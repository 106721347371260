
import { AgGridModule } from 'ag-grid-angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LenderRoutingModule } from './lender-routing.module';

// Lender componenet
import { ProductsMbrComponent } from './components/products-mbr/products-mbr.component';
import { MbrRequestComponent } from './components/mbr-request/mbr-request.component';
import { SharedModule } from '../shared/shared.module';
import { MBRListComponent } from './components/mbr-list/mbr-list.component';
import { MBRRequestListComponent } from './components/mbr-request-list/mbr-list.component';

@NgModule({
  declarations: [
    ProductsMbrComponent,
    MbrRequestComponent,
    MBRListComponent,
    MBRRequestListComponent,
  ],
  imports: [
    CommonModule,
    LenderRoutingModule,
    AgGridModule,
    NgxDropzoneModule,
    NgSelectModule, FormsModule, ReactiveFormsModule,
    SharedModule,
  ],
  exports: [
  ]
})
export class LenderModule { }
