import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DeleteConfirmationDialogComponent } from "../modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component";

@Injectable({
    providedIn: 'root'
  })
export class DeleteConfirmationDialogService {

    constructor(
        private matDialog: MatDialog,
    ) {

    }

    openDeleteConfirmation(message: string, customTitle?: string, customActionText?: string){
        return this.matDialog.open(
            DeleteConfirmationDialogComponent,
            {
                width: '400px',
                disableClose: true,
                data: {
                    message,
                    title: customTitle || 'Delete Confirmation',
                    actionText: customActionText || 'Delete'
                }
            });
    }
}
