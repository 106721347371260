import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

interface IItemText {
  description?: string,
  tooltip?: string,
  class?: string,
  icon?: string
}

@Component({
  selector: 'icon-text-renderer',
  templateUrl: './icon-text-cell-renderer.component.html',
  styleUrls: ['./icon-text-cell-renderer.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
})
export class ItemTextCellRenderer implements ICellRendererAngularComp {
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  public params: any;
  public cellValue: IItemText;

  agInit(params: any): void {
    this.params = params;
    this.cellValue = this.getValueToDisplay(params);
  }

  getValueToDisplay(params: ICellRendererParams): IItemText {
    return {
      description: params.value
        ? 'Full Submission'
        : 'Partial Submission',
      icon: params.value ? 'approval' : 'schedule',
      class: params.value ? 'primary' : 'warning',
    };
  }
}
