import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-information-message',
  templateUrl: './information-message.component.html',
  styleUrls: ['./information-message.component.scss']
})
export class InformationMessageComponent {
  @Input() messageTitle: string;
  @Input() messageBody: string;
}
