import { Injectable } from "@angular/core";
import { BaseService } from "./base-service";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
  })
export class DocumentTypesService extends BaseService {

    private apiUrl = `documentTypes`;
    private httpClient: HttpClient;

    constructor(http: HttpClient) {
      super(http, `documentTypes`);
    }
}
