import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-notification-menu-title',
  templateUrl: './notification-menu-title.html',
  styleUrls: ['./notification-menu-title.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationMenuTitleComponent  {
  @Input() title: string;
}
