<div class="lender-sidebar">
    <span class="close-lender-sidebar" (click)="closeRightSidebar()"><i class="fal fa-times"></i></span>
    <div>
        <h4>{{lenderId ? "Update lender" : "Add lender"}}</h4>
        <form class="animate-labels-form" [formGroup]="lenderForm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <input type="email" class="form-control" placeholder=" " formControlName="email" [readOnly]="lenderId || isLoading"/>
                  <label for="email" class="form-label">Email address</label>
                  <div *ngIf="(lenderForm.controls['email'].dirty || lenderForm.controls['email'].touched)">
                    <div class="error" *ngIf="lenderForm.controls['email'].errors?.['email']">
                        Enter valid email address
                    </div>
                    <div class="error" *ngIf="lenderForm.controls['email'].errors?.['required']">
                        Enter email address
                    </div>
                    <div class="error" *ngIf="lenderForm.controls['email'].errors?.['entityExists']">
                        User already exists with this email
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder=" " formControlName="firstName" [readOnly]="isLoading" />
                  <label class="form-label">First name</label>
                  <div *ngIf="(lenderForm.controls['firstName'].dirty || lenderForm.controls['firstName'].touched)">
                    <div class="error" *ngIf="lenderForm.controls['firstName'].errors?.['required']">
                        Enter First Name
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder=" " formControlName="lastName" [readOnly]="isLoading" />
                  <label class="form-label">Last name</label>
                  <div *ngIf="(lenderForm.controls['lastName'].dirty || lenderForm.controls['lastName'].touched)">
                    <div class="error" *ngIf="lenderForm.controls['lastName'].errors?.['required']">
                        Enter Last Name
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder=" " formControlName="phoneNumber" [readOnly]="isLoading" />
                  <label class="form-label">Phone</label>
                  <div *ngIf="(lenderForm.controls['phoneNumber'].dirty || lenderForm.controls['phoneNumber'].touched)">
                    <div class="error" *ngIf="lenderForm.controls['phoneNumber'].errors?.['required']">
                        Enter Phone Number
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 my-3">
                <app-upload-documents [disabled]="isLoading" (uploadingEvent)="uploadingEventHandler($event)"></app-upload-documents>
              </div>
            </div>

            <div class="text-end mt-3">
              <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="closeRightSidebar()" [disabled]="isLoading">Cancel</button>
              <button (click)="onSubmit()" class="btn btn-primary ms-3" [disabled]="isLoading || lenderForm.invalid" ><i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i> {{isLoading ? "Loading..." : (lenderId ? "Update" : "Add")}}</button>
            </div>
          </form>
    </div>
</div>
