import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { ICardListInfoData } from 'src/app/modules/shared/components/cards/card-list-item/card-list-item.interface';
import { DashboardService } from 'src/app/services/dashboard.service';
import { WelcomeMessageComponent } from '../welcome-message/welcome-message.component';
import { CardStatsComponent } from '../cards/card-stats/card-stats.component';
import { DashboardCardComponent } from './dashboard-card/dashboard-card.component';
import { ROLE_CLAIM } from 'src/app/models/user/role.model';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-pcs-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    WelcomeMessageComponent,
    CardStatsComponent,
    DashboardCardComponent,
  ],
  templateUrl: './pcs-dashboard.component.html',
  styleUrls: ['./pcs-dashboard.component.scss'],
})
export class PcsDashboardComponent {
  user$: Observable<User | null | undefined>;

  dataFormSubmission$: Observable<ICardListInfoData[]>;
  totalFormSubmission$: Observable<number>;
  flexFormSubmission: 'half' | '3-2' | '4-1';
  badgeSizeFormSubmission: 'small' | 'large';
  limitItemFormSubmission: number;
  headerRouteFormSubmission: string;
  detailRouteFormSubmission: string;
  loadingFormSubmission = true;

  dataMarketingMaterial$: Observable<ICardListInfoData[]>;
  totalMarketingMaterial$: Observable<number>;
  flexMarketingMaterial: 'half' | '3-2' | '4-1';
  badgeSizeMarketingMaterial: 'small' | 'large';
  limitItemMarketingMaterial: number;
  headerRouteMarketingMaterial: string;
  detailRouteMarketingMaterial: string;
  loadingMarketingMaterial = true;

  dataMBR$: Observable<ICardListInfoData[]>;
  totalMBR$: Observable<number>;
  flexMBR: 'half' | '3-2' | '4-1';
  badgeSizeMBR: 'small' | 'large';
  limitItemMBR: number;
  headerRouteMBR: string;
  detailRouteMBR: string;
  loadingMBR = true;

  dataFormConsolidation$: Observable<ICardListInfoData[]>;
  totalFormConsolidation$: Observable<number>;
  flexFormConsolidation: 'half' | '3-2' | '4-1';
  badgeSizeFormConsolidation: 'small' | 'large';
  limitItemFormConsolidation: number;
  headerRouteFormConsolidation: string;
  detailRouteFormConsolidation: string;
  loadingFormConsolidation = true;

  role: string = '';

  constructor(
    private auth: AuthenticationService,
    private dashboardService: DashboardService
  ) {
    this.user$ = auth.user$;

    this.user$.subscribe((user) => {
      this.role = user![ROLE_CLAIM];

      switch (this.role) {
        case 'Admin':
        case 'SystemUser':
          this.flexFormSubmission = '3-2';
          this.badgeSizeFormSubmission = 'small';
          this.limitItemFormSubmission = 3;
          this.headerRouteFormSubmission = '/admin/form-submission/list';
          this.detailRouteFormSubmission =
            '/admin/form-submission/details';

          this.flexMBR = '3-2';
          this.badgeSizeMBR = 'large';
          this.limitItemMBR = 3;
          this.headerRouteMBR = '/admin/mbr-request/list';
          this.detailRouteMBR = '/admin/mbr-request/details';

          this.flexFormConsolidation = '3-2';
          this.badgeSizeFormConsolidation = 'large';
          this.limitItemFormConsolidation = 3;
          this.headerRouteFormConsolidation =
            '/admin/form-consolidation/list';
          this.detailRouteFormConsolidation =
            '/admin/form-consolidation/details';

          this.flexMarketingMaterial = '3-2';
          this.badgeSizeMarketingMaterial = 'small';
          this.limitItemMarketingMaterial = 3;
          this.headerRouteMarketingMaterial = '/admin/marketing-material/list';
          this.detailRouteMarketingMaterial =
            '/admin/marketing-material/details';

          break;

        case 'Provider':
          this.flexFormSubmission = '3-2';
          this.badgeSizeFormSubmission = 'small';
          this.limitItemFormSubmission = 3;
          this.headerRouteFormSubmission = '/provider/form-submission/list';
          this.detailRouteFormSubmission =
            '/provider/form-submission/details';

          this.flexFormConsolidation = '3-2';
          this.badgeSizeFormConsolidation = 'large';
          this.limitItemFormConsolidation = 3;
          this.headerRouteFormConsolidation = '/provider/form-consolidation/list';
          this.detailRouteFormConsolidation =
            '/provider/form-consolidation/details';

          this.flexMarketingMaterial = '3-2';
          this.badgeSizeMarketingMaterial = 'small';
          this.limitItemMarketingMaterial = 3;
          this.headerRouteMarketingMaterial = '/provider/marketing-material/list';
          this.detailRouteMarketingMaterial =
            '/provider/marketing-material/details';
          break;

        case 'Lender':
          this.flexFormSubmission = '3-2';
          this.badgeSizeFormSubmission = 'large';
          this.limitItemFormSubmission = 3;
          this.headerRouteFormSubmission = '/lender/form-submission/list';
          this.detailRouteFormSubmission = '/lender/form-submission/details';

          this.flexMBR = '3-2';
          this.badgeSizeMBR = 'large';
          this.limitItemMBR = 3;
          this.headerRouteMBR = '/lender/mbr-request/list';
          this.detailRouteMBR = '/lender/mbr-request/details';

          this.flexMarketingMaterial = '3-2';
          this.badgeSizeMarketingMaterial = 'large';
          this.limitItemMarketingMaterial = 3;
          this.headerRouteMarketingMaterial = '/lender/marketing-material/list';
          this.detailRouteMarketingMaterial =
            '/lender/marketing-material/details';
          break;

        default:
          break;
      }
    });

    this.dataFormSubmission$ = dashboardService.dataFormSubmission$;
    this.totalFormSubmission$ = dashboardService.totalFormSubmission$;
    this.dataFormSubmission$.subscribe(
      () => (this.loadingFormSubmission = false)
    );

    this.dataMarketingMaterial$ = dashboardService.dataMarketingMaterial$;
    this.totalMarketingMaterial$ = dashboardService.totalMarketingMaterial$;
    this.dataMarketingMaterial$.subscribe(
      () => (this.loadingMarketingMaterial = false)
    );

    this.dataMBR$ = dashboardService.dataMBR$;
    this.totalMBR$ = dashboardService.totalMBR$;

    this.dataMBR$.subscribe(() => (this.loadingMBR = false));

    this.dataFormConsolidation$ = dashboardService.dataFormConsolidation$;
    this.totalFormConsolidation$ = dashboardService.totalFormConsolidation$;
    this.dataFormConsolidation$.subscribe(
      () => (this.loadingFormConsolidation = false)
    );
  }

  canShow(permission: string) {
    switch (this.role) {
      case 'Admin':
      case 'SystemUser':
        return true;
      case 'Provider':
        if (permission == 'FormSubmission' || permission == 'FormConsolidation' || permission == 'MarketingMaterial')
          return true;
        else return false;

      case 'Lender':
        if (
          permission == 'MBRReview' ||
          permission == 'FormSubmission' ||
          permission == 'MarketingMaterial'
        )
          return true;
        else return false;

      default:
        return false;
    }
  }
}
