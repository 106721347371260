import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class StatesService extends BaseService {

  private apiUrl = `states`;
  private httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(http, `states`);
  }
}

