import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Portal } from '@angular/cdk/portal';

@Component({
  selector: 'app-general-dialog',
  templateUrl: './general-dialog.component.html',
  styleUrls: ['./general-dialog.component.scss'],
})
export class GeneralDialogComponent implements OnInit{
  title: any;
  message$: Observable<string>;
  buttonText: any;
  countdown$: Observable<number>;

  content: Portal<any>;
  actions: Portal<any>;
  constructor(
    public dialogRef: MatDialogRef<GeneralDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
    this.content = this.data.content;
    this.actions = this.data.actions;

    this.title = this.data.title;
    this.message$ = this.data.message$;
    this.buttonText = this.data.buttonText;
  }

  closeDialog(){
    this.dialogRef.close(true);
  }
}
