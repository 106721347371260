export class DocumentModel {

    
    constructor(file: File, status?: UPLOADSTATUS) {
        this.file =  file;
        this.uploadStatus = status ?? UPLOADSTATUS.PENDING;
    }

    serverFileName: string;
    file: File;
    uploadStatus: UPLOADSTATUS;
} 

export enum UPLOADSTATUS {
    SUCCESS,
    ERROR,
    PENDING
}