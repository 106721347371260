import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { TypeOfResult } from 'src/app/models/typeOfResult/typeOfResult';
import { Role } from 'src/app/models/user/role.model';
import { ISearchItem } from '../search.model';
import { ISelect } from '../../mat-multiselect/mat-multiselect.component';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-general-search-form',
  styleUrls: ['./general-search-form.component.scss'],
  templateUrl: './general-search-form.component.html',
})
export class GeneralSearchFormComponent implements OnInit {
  @Input() filterGroup: FormGroup;
  @Input() role: Role | undefined;
  @Input() loading: boolean;
  @Input() options?: ISearchItem[];
  @Input() states: ISelect[] = [];
  @Input() lenders: ISelect[] = [];
  @Input() providers: ISelect[] = [];
  @Input() administrators: ISelect[] = [];
  @Input() industries: ISelect[] = [];
  @Input() products: ISelect[] = [];
  @Output() navigateTo: EventEmitter<{
    id?: number;
    typeOfResult?: TypeOfResult;
  }> = new EventEmitter();
  @Output() applyFilter: EventEmitter<any> = new EventEmitter();

  @ViewChild('searchTrigger')
  autocompleteTrigger: MatAutocompleteTrigger;

  @ViewChild('filterTrigger') menuTrigger: MatMenuTrigger;
  statusList: ISelect[] = [
    {
      id: 0,
      name: 'All',
    },
    {
      id: 1,
      name: 'Received',
    },
    {
      id: 2,
      name: 'Approved',
    },
    {
      id: 3,
      name: 'Rejected',
    },
  ];

  submissionProgressList: any[] = [
    {
      id: 0,
      name: 'All',
    },
    {
      id: 1,
      name: 'Partial Submission',
    },
    {
      id: 2,
      name: 'Full Submission',
    },
  ];

  isMenuOpen: boolean = false;

  constructor(private router: Router) {}

  ngOnInit() {}

  showOptionsPanel() {
    return (
      this.hasMinLength(this.filterGroup.controls['text'].value) &&
      this.getSearchValue()
    );
  }

  hasMinLength(value: any) {
    return value ? value.length > 3 : false;
  }


  isMenuOpened() {
    return this.isMenuOpen;
  }

  navigateItemTo(id?: number, typeOfResult?: TypeOfResult) {
    this.navigateTo.emit({ id, typeOfResult });
  }

  goToSearchView() {
    let params = this.filterGroup.value;
    params.statesIds = (this.filterGroup.controls['statesIds'].value || []).map(
      (x: any) => x.id
    ).join(',')
    params.lenderIds = (this.filterGroup.controls['lenderIds'].value || []).map(
      (x: any) => x.id
    ).join(',')

    if (this.role === Role.Provider) {
      this.router.navigate([`/provider/search/list`], {
        queryParams: params,
      });
    } else if (this.role === Role.Lender) {
      this.router.navigate([`/lender/search/list`], {
        queryParams: params,
      });
    } else {
      this.router.navigate([`/admin/search/list`], {
        queryParams: params,
      });
    }
    this.closeAutocomplete();
  }

  getSearchValue() {
    return this.filterGroup.controls['text'].value;
  }

  getSearchControl() {
    return this.filterGroup.controls['text'] as FormControl;
  }

  closeAutocomplete() {
    if (this.autocompleteTrigger) {
      this.autocompleteTrigger.closePanel();
    }
  }

  getResultTypeText(type?: TypeOfResult) {
    if (type === TypeOfResult.FormSubmission) {
      return 'Form Submission';
    } else if (type === TypeOfResult.FormConsolidation) {
      return 'Form Consolidation';
    } else if (type === TypeOfResult.MBRRequest) {
      return 'MBR Request';
    } else if (type === TypeOfResult.MarketingMaterial) {
      return 'Marketing Material';
    } else if (type === TypeOfResult.ExternalApproval) {
      return 'External Approval';
    }
    return;
  }

  onPressEnter() {
    this.menuTrigger.closeMenu();
    this.autocompleteTrigger.closePanel();

    this.goToSearchView();
  }

  getMostRecentResultFor() {
    return (this.filterGroup.controls['text'].value &&
      this.options &&
      this.options?.length > 0) ||
      this.loading
      ? 'Most recent results for '
      : 'No results found for ';
  }

  onApplyFilter() {
    this.applyFilter.emit();
    this.goToSearchView()
  }

  onClearFilters(event: Event) {
    event.stopPropagation();
    this.filterGroup.patchValue({
      statesIds: [],
      lenderIds: [],
      status: null,
      submissionProgressId: null,
      providerOrganizationId: null,
      administratorId: null,
      productId: null,
      industryId: null,
    });
  }
}
