<div class="dialog-header">
  <h5>{{ title }}</h5>
  <button class="dialog-header-close" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">
  <p *ngIf="!content">{{ message$ | async }}</p>
  <ng-template [cdkPortalOutlet]="content"></ng-template>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    *ngIf="!actions"
    mat-flat-button
    color="primary"
    type="button"
    [mat-dialog-close]="true"
  >
    {{ buttonText }}
  </button>
  <ng-template [cdkPortalOutlet]="actions"></ng-template>
</mat-dialog-actions>
