import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { APIResponseModel } from '../models/api-response-model';

@Injectable({
  providedIn: 'root'
})

export class MBRDocumentsService extends BaseService {

  constructor(http: HttpClient) {
    super(http, `mbrdocuments`);
  }

  uploadDocument(id: string, file: File): Observable<APIResponseModel<any>> {

    let formData = new FormData();
    formData.append(file.name, file);

    return this.http.post<APIResponseModel<any>>(`${this.baseUrl}/documentupload/${id}`, formData)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, {});
        })
    );
  }

  getByProduct(id: string): Observable<APIResponseModel<any>> {
    return this.http.get<APIResponseModel<any>>(`${this.baseUrl}/product/${id}`)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, {});
        })
    );
  }

  getByProductAndLender(id: string, lenderId: string): Observable<APIResponseModel<any>> {
    return this.http.get<APIResponseModel<any>>(`${this.baseUrl}/product/${id}/${lenderId}`)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, {});
        })
    );
  }

  uploadMBR(productId: string, data: any): Observable<APIResponseModel<any>> {

    return this.http.post<APIResponseModel<any>>(`${this.baseUrl}/upload/${productId}`, data)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, {});
        })
    );
  }

  uploadLenderMBR(productId: string, lenderId: string, data: any): Observable<APIResponseModel<any>> {

    return this.http.post<APIResponseModel<any>>(`${this.baseUrl}/upload/${productId}/${lenderId}`, data)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, {});
        })
    );
  }

  makeDefault(productId: string, version: string): Observable<APIResponseModel<any>> {

    return this.http.post<APIResponseModel<any>>(`${this.baseUrl}/makedefault/${productId}/${version}`, {})
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, {});
        })
    );
  }

  makeDefaultByLender(productId: string, lenderId: string, version: string): Observable<APIResponseModel<any>> {

    return this.http.post<APIResponseModel<any>>(`${this.baseUrl}/makedefault/${productId}/${lenderId}/${version}`, {})
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, {});
        })
    );
  }

}
