import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Observable, catchError } from 'rxjs';
import { APIResponseModel } from '../models/api-response-model';

@Injectable({
  providedIn: 'root'
})
export class LenderOrganizationsService extends BaseService {

  private apiUrl = `lenderorganizations`;
  private httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(http, `lenderorganizations`);
  }
  changeUserAvailability(id: string, enabled: boolean): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/change-availability`, { id, enabled: enabled })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }
}
