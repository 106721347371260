import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { LenderPopupComponent } from 'src/app/modules/admin/components/popups/lender-popup/lender-popup.component';
import { LenderOrganizationsService } from 'src/app/services/lender.organizations.service';

@Component({
  selector: 'app-lenders-organization-list',
  templateUrl: './lenders-organization-list.component.html',
  styleUrls: ['./lenders-organization-list.component.scss']
})
export class LendersOrganizationListComponent {
  public organizationId: any;

  @ViewChild(LenderPopupComponent) popup: LenderPopupComponent;

  constructor(private service: LenderOrganizationsService, private router: Router) { }

   // Each Column Definition results in one Column.
   public columnDefs: ColDef[] = [
    {
      field:'name',
      flex:2,
      suppressMenu: true, floatingFilter: true
    },
    {
      field:'code',
      flex:2,
      suppressMenu: true, floatingFilter: true
    },
  ];

  public rowData$!: any[];

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  onGridReady(params: GridReadyEvent) {
    this.getGridData();
  }


  private getGridData() {
    this.service.get().subscribe(r => {
      if(!r.isError) {
        this.rowData$ = r.data;
      }
    });
  }

popupUpdate(data: any) {
  this.getGridData();
}

}
