<div
  class="pro-card"
  [ngClass]="{ flex32: flex == '3-2', flex41: flex == '4-1' }"
>
  <div class="pro-card-info">
    <span class="info-content" *ngFor="let info of infoContent"
      ><strong>{{ info.label }}:</strong>
      <ng-container *ngIf="isArray(info.value);else isNotArrayTemplate">
        <div class="array-row">
          <span *ngFor="let item of info.value">
            {{ item }}
          </span>
        </div>
      </ng-container>
      <ng-template #isNotArrayTemplate>
        {{ info.value }}
      </ng-template>
    </span
    >
  </div>
  <div class="vertical-separator"></div>
  <div class="pro-card-status">
    <div class="status-list">
      <app-status-badge
        class="status-badge"
        *ngFor="let status of statusContent | slice : 0 : 3"
        [text]="status.label"
        [status]="status.value"
        [size]="statusContent.length == 1 ? 'large' : badgeSize"
      />
      <app-status-badge
        class="status-badge"
        *ngIf="statusContent.length > 3"
        [text]="getExtraCount()"
        [status]="'primary'"
        [size]="badgeSize"
      />
    </div>
    <div class="item-detail">
      <button (click)="goDetail()" type="button">
        <span class="material-icons-outlined icon">visibility</span>
      </button>
    </div>
  </div>
</div>
