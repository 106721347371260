<a class="notification-menu-item-container">
  <div class="notification-item-header">
    <strong>{{ title }}</strong>
  </div>
  <div class="notification-item-content">
    <p>{{ description }}</p>
  </div>
  <div class="notification-item-footer">
    <div class="date">
      <span class="material-icons-outlined icon">calendar_today</span>
      <span class="text">{{date}}</span>
    </div>
    <div class="time">
      <span class="material-icons-outlined icon">schedule</span>
      <span class="text">{{time}}</span>
    </div>
  </div>
</a>
