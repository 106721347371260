<div class="right-sidebar">
  <div class="right-sidebar-header">
    <h3 class="title">{{title}}</h3>
    <button class="close-button" mat-icon-button type="button" (click)="cancelClicked()">
      <mat-icon
        fontSet="material-icons-outlined"
        >close</mat-icon
      >
    </button>
  </div>
  <ng-content></ng-content>
  <div class="right-sidebar-footer">
    <button mat-stroked-button class="primary" (click)="cancelClicked()">
      Cancel
    </button>
    <button [disabled]="disableSubmit || loading"
      mat-stroked-button class="primary"
      *ngIf="hasSecondary"
      (click)="secondaryClicked()">
      {{ footerSecondaryCustomLabel }}
    </button>
    <button mat-flat-button
      color="primary"
      type="button"
      (click)="submitClicked()"
      [disabled]="disableSubmit || loading"
    >
      <span>
        <mat-spinner *ngIf="loading" diameter="24"></mat-spinner>
        {{ loading ? '' : footerSubmitCustomLabel ? footerSubmitCustomLabel : 'Update'}}
      </span>
    </button>
  </div>
</div>
