<div
  class="status-badge"
  [ngClass]="{
    warn: status == 'warn',
    success: status == 'success',
    info: status == 'info',
    primary: status == 'primary',
    secondary: status == 'secondary',
    error: status == 'error',
    sm: size == 'small',
    lg: size == 'large',
  }"
  [matTooltip]="getTooltipText()"
>
  <span class="text">{{ (size == 'large' ? text : getInitials()) | uppercase}}</span>
  <ng-container [ngSwitch]="status">
    <span *ngSwitchCase="'info'" class="material-icons icon"
      >hourglass_top</span
    >
    <span *ngSwitchCase="'success'" class="material-icons icon">check</span>
    <span *ngSwitchCase="'error'" class="material-icons icon">block</span>
    <span *ngSwitchCase="'secondary'" class="material-icons icon"
      >mail_outline</span
    >
    <span *ngSwitchCase="'warn'" class="material-icons-outlined icon warn"
      >front_hand</span
    >
    <span *ngSwitchDefault></span>
  </ng-container>
</div>
