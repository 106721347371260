<main class="auth-pages">
    <div class="card">
        <div class="card-body">
            <div class="not-found">
                <h4 class="mb-4"><i class="far fa-exclamation-triangle"></i></h4>
                <h6>Access denied</h6>
                <p class="m-3">Please contact the admin </p>
                <div class="text-center">
                    <button class="btn btn-primary  mx-1" (click)="goToLogin()"><i class="fal fa-tachometer-fast"></i> Go Back</button>
                </div>
            </div>
        </div>
    </div>
</main>
