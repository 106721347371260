import { Component, EventEmitter, Injector, Input,  OnInit,  Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UploadDocumentsComponent } from '../upload-documents/upload-documents.component';
import { FormSubmissionsService } from 'src/app/services/form-submissions.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MarketingMaterialService } from 'src/app/services/marketing-material.service';
import { UsersService } from 'src/app/services/users.service';
import { Role } from 'src/app/models/user/role.model';

@Component({
  selector: 'app-approve-box',
  templateUrl: './approve-box.component.html',
  styleUrls: ['./approve-box.component.scss']
})
export class ApproveBoxComponent implements OnInit {

  isLoading: boolean = false;
  private service: FormSubmissionsService | MarketingMaterialService;
  currentUrl: string = '';
  userRole: Role | undefined;

  form: FormGroup = new FormGroup({
    comments: new FormControl(''),
    lenderIds: new FormControl('', [Validators.required])
  });

  constructor(private injector: Injector, private usersService: UsersService) {
    usersService.userRole$.subscribe(role => this.userRole = role);
  }

  ngOnInit(): void {
    if(this.lendersUrl === '') {
      this.form.controls['lenderIds'].removeValidators(Validators.required);
    }

    if (this.type === 'submission') {
      this.service = this.injector.get(FormSubmissionsService);
    }

    if (this.type === 'marketingmaterial') {
      this.service = this.injector.get(MarketingMaterialService);
    }
  }

  @Input() entityId: string;
  @Input() lendersUrl: string;
  @Input() type: string;
  @Input() decision: 'approve' | 'reject' = 'approve';
  @Output() actionResult = new EventEmitter<any>();

  uploadingEventHandler(status: boolean): void {
    this.isLoading = status;
  }

  getControl(controlName: string): any {
    return this.form.get(controlName);
  }


  onSubmit() {

    this.isLoading = true;

    let data = this.form.value;
    if (this.lendersUrl === '') {
      data['lenderIds'] = [];
    }
    data['documents'] = this.documentUploader.uploadedFiles();

    this.service.updateStatus(this.entityId, this.decision, {...data, lenderIds: [data.lenderIds]})
      .subscribe(r => {
        this.isLoading = false;
        this.form.reset();
        this.actionResult.emit({ result: true});
      });

  }

  onCancel() {
    this.form.reset();
    this.actionResult.emit({ result: false });
  }

  @ViewChild(UploadDocumentsComponent) documentUploader: UploadDocumentsComponent;
}
