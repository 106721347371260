import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { IProviderOrganizationDocument } from '../models/provider/provider-license.model';
import { APIResponseModel } from '../models/api-response-model';
import { Observable, catchError } from 'rxjs';

interface IProspectQueryParams{
  organizationId: number;
  applicableProducts: number[];
  applicableStates: number[];
}

@Injectable({
  providedIn: 'root'
})
export class ProviderDocumentService extends BaseService {

  private apiUrl = `providerDocuments`;
  private httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(http, `providerDocuments`);
  }

  prospect(filters: any | null = null): Observable<APIResponseModel<IProviderOrganizationDocument[]>> {
    return this.http
      .get<APIResponseModel<any[]>>(
        `${this.baseUrl}/prospects`,
        filters ? { params: filters as any} : { params: {} }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, []);
        })
      );
  }
}
