import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductsMbrComponent } from './components/products-mbr/products-mbr.component';
import { MBRListComponent } from './components/mbr-list/mbr-list.component';
import { PcsDashboardComponent } from '../shared/components/pcs-dashboard/pcs-dashboard.component';
import { AuthenticationGuard } from 'src/app/authentication.guard';
import { NotificationsListComponent } from '../shared/views/notifications-list/notifications-list.component';
import { HomeComponent } from '../shared/components/home/home.component';
const routes: Routes = [
  {
    path: '', component: HomeComponent, children: [
      { path: 'dashboard', component: PcsDashboardComponent },
      { path: 'mbr-list', component: MBRListComponent },
      { path: 'product-mbr/:id', component: ProductsMbrComponent },
      { path: 'notifications-list', component: NotificationsListComponent },
      {
        path: 'form-submission',
        loadChildren: () =>
          import(
            '../shared/views/form-submission/form-submission.route'
          ).then((mod) => mod.FORM_SUBMISSION_ROUTES),
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'search',
        loadChildren: () =>
          import(
            '../shared/views/general-search/general-search.route'
          ).then((mod) => mod.GENERAL_SEARCH_ROUTES),
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'marketing-material',
        loadChildren: () =>
          import(
            '../shared/views/marketing-material/marketing-material.route'
          ).then((mod) => mod.MARKETING_MATERIAL_ROUTES),
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'external-approval',
        loadChildren: () =>
          import(
            '../shared/views/external-approval/external-approval.route'
          ).then((mod) => mod.EXTERNAL_APPROVAL_ROUTES),
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'mbr-request',
        loadChildren: () =>
          import(
            '../shared/views/mbr-request/mbr.route'
          ).then((mod) => mod.MBR_ROUTES),
        canActivate: [AuthenticationGuard],
      },
      { path: '', redirectTo: '/lender/dashboard', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LenderRoutingModule { }
