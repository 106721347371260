import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UploadDocumentsComponent } from 'src/app/modules/shared/components/upload-documents/upload-documents.component';
import { LendersService } from 'src/app/services/lenders.service';
import { entityExistsValidator } from 'src/app/validators/entity-exists.validator';

@Component({
  selector: 'lender-popup',
  templateUrl: './lender-popup.component.html',
  styleUrls: ['./lender-popup.component.scss']
})
export class LenderPopupComponent implements OnInit {

  isLoading: boolean = false;
  lenderId: string | null;
  lenderData: any = {};

  @ViewChild(UploadDocumentsComponent) uploadDocumentsComponent: UploadDocumentsComponent;

  lenderForm: FormGroup = new FormGroup({
    email: new FormControl(this.lenderData.email, { validators: [Validators.email, Validators.required], updateOn: 'blur'}),
    firstName: new FormControl(this.lenderData.firstName, [Validators.required]),
    lastName: new FormControl(this.lenderData.lastName, [Validators.required]),
    phoneNumber: new FormControl(this.lenderData.phoneNumber),
    organizationId: new FormControl(this.lenderData.organizationId, [Validators.required]),
  }); 

  @Output() updateEvent = new EventEmitter<any>();
  @Input() organizationId: string;

  constructor(private lenderService: LendersService) {
  }

  ngOnInit(): void {
  }

  closeRightSidebar(){
    this.lenderForm.reset();
    $('.lender-sidebar').removeClass('open');
  }

  openRightSidebar(id: string | null = null) {

    debugger;

    this.lenderForm.reset();
    this.lenderId = id;
    this.lenderForm.get('organizationId')?.setValue(this.organizationId);

    $('.lender-sidebar').toggleClass('open');

    if (this.lenderId) {
      
      this.isLoading = true;
      this.lenderService.getById(this.lenderId).subscribe(value => {
        this.lenderForm.controls['email'].clearAsyncValidators();
        this.lenderForm.controls['email'].clearValidators();

        this.lenderForm.reset(value.data);
        this.isLoading = false;
        debugger;
      });
    }
    else {
      this.lenderForm.controls['email'].setAsyncValidators([entityExistsValidator(this.lenderService)]);
    }
  }

  onSubmit() {

    this.isLoading = true;
    let data = this.lenderForm.value;
    if(this.lenderId) {
      this.lenderService.update(this.lenderId, data).subscribe(data => {
        this.isLoading = false;
        this.updateEvent.emit(this.lenderId);
        this.closeRightSidebar();
        this.isLoading = false;
      });
    }
    else {
      this.lenderService.add(data).subscribe(data => {
        this.isLoading = false;
        this.updateEvent.emit(this.lenderId);
        this.closeRightSidebar();
        this.isLoading = false;
      });
    }
  }

  uploadingEventHandler(status: boolean): void {
    this.isLoading = status;
  }

 }
  
