import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

export type STATUS_TYPE = 'primary' | 'warn' | 'info' | 'error' | 'secondary' | 'success';

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
})
export class StatusBadgeComponent {
  @Input() text: string;
  @Input() status: STATUS_TYPE;
  @Input() size?: 'small' | 'large' = 'small';
  @Input() tooltip?: string;

  getInitials() {
    if(this.status == 'primary') return this.text;

    const [initial_1, initial_2] = this.text.split(' ');

    if(initial_2) {
      return initial_1[0] + initial_2[0];
    }
    else {
      return initial_1.slice(0,2)
    }
  }

  getTooltipText() {
    if(this.tooltip) {
      return this.tooltip;
    }
    return this.text.startsWith('+') ? '' : this.text;
  }
}
