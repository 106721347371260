<mat-drawer-container hasBackdrop>
  <mat-drawer autoFocus="false" #drawer mode="over" position="end">
    <app-right-sidebar-container
      [title]="'Notification Preferences'"
      (cancel)="close()"
      (submit)="updatePreferences()"
    >
      <form [formGroup]="preferencesform" class="notification-panel">
        <div class="form-preferences">
          <h6>Customize your notification preferences</h6>
          <div class="form-item">
            <div class="form-text">
              <span>Final state</span>
              <p>
                Receive a notification when your form has been approved or
                rejected.
              </p>
            </div>
            <mat-slide-toggle
              [color]="'primary'"
              formControlName="reachFinalState"
            ></mat-slide-toggle>
          </div>
          <div class="form-item">
            <div class="form-text">
              <span>Modifications and comments</span>
              <p>
                Receive notifications when there are replies to your forms or whether
                they were modified
              </p>
            </div>
            <mat-slide-toggle
              [color]="'primary'"
              formControlName="modified"
            ></mat-slide-toggle>
          </div>
          <h6 class="mt-32">Customize your emails notification frequency</h6>
          <div class="custom-form-field">
            <mat-radio-group
              class="column-options"
              color="primary"
              formControlName="frequency"
            >
              <mat-radio-button [value]="1" class="form-item">
                <div class="form-text">
                  <span>Per Event</span>
                  <p>Get an email for each event</p>
                </div>
              </mat-radio-button>
              <mat-radio-button [value]="0" class="form-item">
                <div class="form-text">
                  <span>Daily Summary</span>
                  <p>Receive one email at the end of each day</p>
                </div>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </form>
    </app-right-sidebar-container>
  </mat-drawer>
  <mat-drawer-content>
    <main>
      <app-main-navigation
        (openPreferences)="onOpenPreferences()"
      ></app-main-navigation>
      <div class="left-sidebar" app-left-sidebar></div>
      <div class="main-container">
        <section class="main-section">
          <router-outlet></router-outlet>
        </section>
      </div>
    </main>
  </mat-drawer-content>
</mat-drawer-container>
