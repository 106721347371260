import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InitialsPipe } from '../../pipes/initials.pipe';
import { IAvatarConfig } from './avatar-config.interface';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-avatar-profile',
  templateUrl: './avatar-profile.component.html',
  styleUrls: ['./avatar-profile.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InitialsPipe, CommonModule ]
})
export class AvatarProfileComponent  {
  @Input() fullName?: string;
  @Input() email?: string;
  @Input() config?: IAvatarConfig = {
    avatarSize: 'sm',
  }
}
