import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import {  map } from 'rxjs';
import { IProduct } from 'src/app/models/product/product.model';
import { IProviderOrganizationDocumentType } from 'src/app/models/provider/provider-license.model';
import { IState } from 'src/app/models/state/state.model';
import { DocumentTypesService } from 'src/app/services/document-types.service';
import { ProductsService } from 'src/app/services/products.service';
import { ProvidersService } from 'src/app/services/providers.service';
import { StatesService } from 'src/app/services/states.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss']
})
export class OrganizationDetailsComponent implements OnInit{  
  public states: IState[] = [];
  public products: IProduct[] = [];
  public documentTypes: IProviderOrganizationDocumentType[] = [];

  public organizationId : string;

  constructor(private providerService: ProvidersService, private stateService: StatesService, private productsService: ProductsService,
     private providerDocumentService: DocumentTypesService, private userService: UsersService,
  ) {
    
  }

  ngOnInit(): void {
    this.getOrganizationId();
    this.getStates();
    this.getProducts();
    this.getDocumentTypes();
  }

 // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    {
      field: 'firstName',
      flex:2,
      filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true
    },
    { field: 'lastName', flex:2, filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true},
    { field: 'email', flex:3, filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true},
    { field: 'phoneNumber', flex:2, filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true},
  ];
  public rowData$!: any[];

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;


  private getOrganizationId() {
    this.userService.organizationId$
      .subscribe(id => {
        this.organizationId = `${id}`;
      });
  }

  private getProducts() {
    this.productsService.get().subscribe(data => {
      if (!data.isError) {
        this.products = data.data;
      }
    });
  }

  private getStates() {
    this.stateService.get().subscribe(data => {
      if (!data.isError) {
        this.states = data.data;
      }
    });
  }

  private getDocumentTypes() {
    this.providerDocumentService.get().subscribe(data => {
      if (!data.isError) {
        this.documentTypes = data.data;
      }
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.getGridData();
  }

  private getGridData() {
    this.providerService.getByOrganizationId(this.organizationId!).subscribe(r => {
      this.rowData$ = r.data;
    });
  }

  popupUpdate(data: any) {
    this.getGridData();
  }

}
