<main class="auth-pages">
    <div class="card">
        <div class="card-body">
            <div class="not-found">
                <!-- <h4>404</h4> -->
                <h4 class="mb-4"><i class="far fa-exclamation-triangle"></i></h4>
                <h6>page not found</h6>
                <p>Sorry, the page you're looking for doesn't exist </p>
                <div class="text-center">
                    <a href="" class="btn btn-outline-primary mx-1"><i class="far fa-arrow-left"></i> Go Back</a>
                    <button class="btn btn-primary  mx-1" (click)="goToDashboard()"><i class="fal fa-tachometer-fast"></i> Dashboard</button>
                </div>
            </div>
        </div>
    </div>
</main>