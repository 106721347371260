import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, concatMap, flatMap, map, mergeMap, of, pipe, switchMap, tap } from 'rxjs';
import { AuthenticationService } from './services/authentication.service';
import { AuthGuard, AuthService } from '@auth0/auth0-angular';
import { ROLE_CLAIM, Role } from './models/user/role.model';
import { PERMISSION_CLAIM, Permission } from './models/user/permissions.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {

  
  constructor(private authService: AuthenticationService, private router: Router, private _authguard: AuthGuard) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url = state.url;
    
    return this._authguard
          .canActivate(route,state)
          .pipe(
            concatMap(authenticated => {
              if(!authenticated)
                return of(authenticated);

              return this.authenticate(route, state.url);
            })
          );
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url = state.url;
    return this.authenticate(childRoute, url);
  }

  private authenticate(route: ActivatedRouteSnapshot, url: any): Observable<boolean> {
    const rolesRequired: Role[] | undefined = route.data['roles'];
    const permissionsRequired: Permission[] | undefined = route.data['permissions'];
    

    return this.authService.user$.pipe(
      map(user => {
        if(!user)
          return false;

        const userRole: Role | undefined = user[ROLE_CLAIM];
        const userPermissions: Permission[] | undefined = user[PERMISSION_CLAIM];

        if(rolesRequired && rolesRequired.length > 0){
          if(!userRole)
            return false;

          const hasRoles = rolesRequired.some(role => userRole === role);

          if(!hasRoles)
            return false;
        }

        if(permissionsRequired && permissionsRequired.length > 0){
          if(!userPermissions)
            return false;

          const hasPermissions = permissionsRequired.every(permission => userPermissions.includes(permission));

          if(!hasPermissions)
            return false;
        }

        return true;
      }),
      tap(authenticated => {
        if(!authenticated)
          this.router.navigate(['/not-found']);
      })
    )
  }
  
}
