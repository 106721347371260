import { Component, Input } from '@angular/core';
import { ImageRendererService } from './image-renderer.service';

@Component({
  selector: 'app-image-renderer',
  template: `<img [src]="getImagePath(imageName)" [alt]="altText">`,
  styleUrls: ['./image-renderer.component.scss'],
  standalone: true
})
export class ImageRendererComponent {
  @Input() imageName: string;
  @Input() altText: string = '';

  constructor(private imageRendererService: ImageRendererService) { }

  getImagePath(imageName: string): string {
    return this.imageRendererService.getIconsPath(imageName);
  }
}
