import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ROLE_CLAIM, Role } from 'src/app/models/user/role.model';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  constructor(private auth: AuthenticationService, private router: Router) {
  }

  goToDashboard(){
    this.auth.user$.subscribe(user => {
      if(!user)
      {
        this.router.navigate(['/']);
        return;
      }

      const role: Role | undefined = user[ROLE_CLAIM];

      switch(role){
        case Role.Admin:
        case Role.SystemUser:
          this.router.navigate(['admin/dashboard']);
          break;
        case Role.Provider:
          this.router.navigate(['provider/dashboard']);
          break;
        case Role.Lender:
          this.router.navigate(['lender/dashboard']);
          break;
        default:
          this.auth.logout();
          break;
      }
    })
  }
}
