<div class="dashboard-container">
  <app-welcome-message
    [message]="'Welcome ' + (user$ | async)?.['given_name'] + '!'"
  />
  <div class="card-stats-container">
    <app-card-stats
      class="card-stats"
      [title]="'Form Submissions'"
      [value]="totalFormSubmission$ | async"
      *ngIf="canShow('FormSubmission')"
      [loading]="loadingFormSubmission"
    />
    <app-card-stats
      class="card-stats"
      [title]="'MBR Review Requests'"
      [value]="totalMBR$ | async"
      *ngIf="canShow('MBRReview')"
      [loading]="loadingMBR"
    />
    <app-card-stats
      class="card-stats"
      [title]="'Form Consolidation Requests'"
      [value]="totalFormConsolidation$ | async"
      *ngIf="canShow('FormConsolidation')"
      [loading]="loadingFormConsolidation"
    />

    <app-card-stats
      class="card-stats"
      [title]="'Marketing Materials'"
      [value]="totalMarketingMaterial$ | async"
      *ngIf="canShow('MarketingMaterial')"
      [loading]="loadingMarketingMaterial"
    />
  </div>

  <div class="card-main-container">
    <app-dashboard-card
      class="dashboard-card"
      *ngIf="canShow('FormSubmission')"
      [title]="'Form Submissions'"
      [listData]="dataFormSubmission$ | async"
      [limitItems]="limitItemFormSubmission"
      [headerRouteLink]="headerRouteFormSubmission"
      [detailRouteLink]="detailRouteFormSubmission"
      [modeFlex]="flexFormSubmission"
      [modeBadge]="badgeSizeFormSubmission"
      [loading]="loadingFormSubmission"

    />

    <app-dashboard-card
      class="dashboard-card"
      *ngIf="canShow('MBRReview')"
      [title]="'MBR Review Requests'"
      [listData]="dataMBR$ | async"
      [limitItems]="limitItemMBR"
      [headerRouteLink]="headerRouteMBR"
      [detailRouteLink]="detailRouteMBR"
      [modeFlex]="flexMBR"
      [modeBadge]="badgeSizeMBR"
      [loading]="loadingMBR"
    />

    <app-dashboard-card
      *ngIf="canShow('FormConsolidation')"
      class="dashboard-card"
      [title]="'Form Consolidation Requests'"
      [listData]="dataFormConsolidation$ | async"
      [limitItems]="limitItemFormConsolidation"
      [headerRouteLink]="headerRouteFormConsolidation"
      [detailRouteLink]="detailRouteFormConsolidation"
      [modeFlex]="flexFormConsolidation"
      [modeBadge]="badgeSizeFormConsolidation"
      [loading]="loadingFormConsolidation"
    />

    <app-dashboard-card
      class="dashboard-card"
      *ngIf="canShow('MarketingMaterial')"
      [title]="'Marketing Materials'"
      [listData]="dataMarketingMaterial$ | async"
      [limitItems]="limitItemMarketingMaterial"
      [headerRouteLink]="headerRouteMarketingMaterial"
      [detailRouteLink]="detailRouteMarketingMaterial"
      [modeFlex]="flexMarketingMaterial"
      [modeBadge]="badgeSizeMarketingMaterial"
      [loading]="loadingMarketingMaterial"
    />
  </div>
</div>
