<div class="row  my-3" *ngIf="hide">
    <div class="col-lg-8 col-md-10">
        <div class="submit-box submit-sendback">
            <app-upload-documents #sendbackFiles [disabled]="isLoading" (uploadingEvent)="uploadingEventHandler($event)" />
            <div class="animate-labels-form mt-3">
                <form (submit)="onSubmit()" [formGroup]="form">
                    <div class="form-group">
                        <textarea class="form-control" rows="5" type="text" onclick="(this.type='date')" formControlName="comments"
                            placeholder=" " [readOnly]="isLoading"></textarea>
                        <label>Rejection Notes</label>
                        <div *ngIf="(form.controls['comments'].dirty || form.controls['comments'].touched)">
                            <div class="error" *ngIf="form.controls['comments'].errors?.['required']">
                                Enter your rejection notes
                            </div>
                        </div>
                    </div>

                    <div class="text-end">
                        <button class="btn btn-outline-danger"  type="button" (click)="onCancel()" [disabled]="isLoading">Cancel</button>
                        <button class="btn btn-danger ms-3" type="submit" [disabled]="isLoading || form.invalid"><i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i> <i  *ngIf="!isLoading" class="far fa-times-octagon"></i>{{(isLoading ? 'Replying...': 'Reject')}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>