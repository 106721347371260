<div class="row  my-3">
    <div class="col-lg-8 col-md-10">
        <div class="submit-box submit-sendback">
            <app-upload-documents #sendbackFiles [disabled]="isLoading" (uploadingEvent)="uploadingEventHandler($event)" />
            <div class="animate-labels-form mt-3">
                <form (submit)="onSubmit()" [formGroup]="form">
                    <div class="form-group" *ngIf="lendersUrl !== ''">
                        <app-select-dropdown [serviceUrl]="lendersUrl" [control]="getControl('lenderIds')" [label]="'Select Lender'" [valueField]="'id'" [displayField]="'name'" [disabled]="isLoading"></app-select-dropdown>
                    </div>
                    
                    <div class="form-group" *ngIf="userRole === 'Lender'">
                        <textarea class="form-control" rows="5" type="text" onclick="(this.type='date')" formControlName="comments"
                            placeholder=" " [readOnly]="isLoading"></textarea>
                        <label>{{decision === 'approve' ? 'Approval' : 'Rejection'}} Notes</label>
                        <div *ngIf="(form.controls['comments'].dirty || form.controls['comments'].touched)">
                            <div class="error" *ngIf="form.controls['comments'].errors?.['required']">
                                Enter {{decision === 'approve' ? 'approval' : 'rejection'}} notes
                            </div>
                        </div>
                    </div>

                    <div class="text-end">
                        <button class="btn btn-outline-danger" type="button" (click)="onCancel()" [disabled]="isLoading">Cancel</button>
                        <button class="btn ms-3" [ngClass]="{'btn-success': decision === 'approve', 'btn-danger': decision !== 'approve'}" type="submit" [disabled]="isLoading || form.invalid"><i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i> <i  *ngIf="!isLoading" [ngClass]="{'fal fa-badge-check': decision === 'approve', 'far fa-times-octagon': decision !== 'approve'}"></i>{{ decision === 'approve' ? (isLoading ? 'Approving...': 'Approve') : (isLoading ? 'Rejecting...': 'Reject')}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>