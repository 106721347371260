
import { AgGridModule } from 'ag-grid-angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProviderRoutingModule } from './provider-routing.module';

import { ProductMbrListComponent } from './components/mbr-list/mbr-list.component';
import { SharedModule } from '../shared/shared.module';
import { LendersOrganizationListComponent } from './components/lenders-organization-list/lenders-organization-list.component';
import { OrganizationDetailsComponent } from './components/organization-details/organization-details.component';


@NgModule({
  declarations: [
    ProductMbrListComponent,
    LendersOrganizationListComponent,
    OrganizationDetailsComponent

  ],
  imports: [
    CommonModule,
    ProviderRoutingModule,
    AgGridModule,
    NgxDropzoneModule,
    FormsModule, ReactiveFormsModule,
    SharedModule,
  ]
})
export class ProviderModule { }
