import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastMessageComponent } from '../modules/shared/components/toast-message/toast-message.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private snackBar: MatSnackBar) { }

  success(title: string, message: string) {
    this.snackBar.openFromComponent(ToastMessageComponent, {
      data: { title, content: message, status: 'success' },
      duration: 5000
    });
  }

  error(title: string, message: string) {
    this.snackBar.openFromComponent(ToastMessageComponent, {
      data: { title, content: message, status: 'error' },
      duration: 5000
    });
  }

}
