import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import {
  debounceTime,
  filter,
  interval,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationService } from 'src/app/services/notifications.service';
import { SearchService } from '../../../../services/search.service';
import { ISearchItem, ISearchResult } from './search.model';
import { Role } from 'src/app/models/user/role.model';
import { TypeOfResult } from 'src/app/models/typeOfResult/typeOfResult';
import { UsersService } from 'src/app/services/users.service';
import { StatesService } from 'src/app/services/states.service';
import { ProviderOrganizationsService } from 'src/app/services/provider.organizations.service';
import { AdministratorService } from 'src/app/services/administrator.service';
import { IndustriesService } from 'src/app/services/industries.service';
import { ProductsService } from 'src/app/services/products.service';
import { LendersService } from 'src/app/services/lenders.service';
import { LenderOrganizationsService } from 'src/app/services/lender.organizations.service';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
export class MainNavigationComponent implements OnInit {
  user$: Observable<User | null | undefined>;
  userRole: Role | undefined;
  userOrganization: string | undefined;
  notifications: any[] = [];

  filterGroup = new FormGroup({
    text: new FormControl('', [Validators.minLength(4)]),
    statesIds: new FormControl(),
    lenderIds: new FormControl(),
    status: new FormControl(),
    submissionProgressId: new FormControl(),
    providerOrganizationId: new FormControl(),
    administratorId: new FormControl(),
    productId: new FormControl(),
    industryId: new FormControl(),
  });

  filteredOptions?: ISearchItem[];
  loading: boolean;
  private subscription: Subscription;

  statesList: any[] = [];
  providerList: any[] = [];
  administratorList: any[] = [];
  industryList: any[] = [];
  productList: any[] = [];
  lendersList: any[] = [];

  @Output() openPreferences: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private authService: AuthenticationService,
    public notificationService: NotificationService,
    private router: Router,
    private userService: UsersService,
    private searchService: SearchService,
    private statesService: StatesService,
    private providerService: ProviderOrganizationsService,
    private administratorService: AdministratorService,
    private industriesService: IndustriesService,
    private productsService: ProductsService,
    private lendersService: LenderOrganizationsService,
  ) {
    this.user$ = authService.user$;

    this.filterGroup.controls['text'].valueChanges
      .pipe(
        filter((value) => this.validateInput(value)),
        tap(() => (this.loading = true)),
        debounceTime(2000),
        switchMap((value) => this._filter(value || '')),
        tap(() => (this.loading = false))
      )
      .subscribe((options: any) => {
        this.filteredOptions = options.data.flatMap(
          (optionGroup?: ISearchResult) => optionGroup?.items
        ) as ISearchItem[];
      });
  }

  ngOnInit(): void {
    this.userService.userRole$.subscribe((role) => {
      this.userRole = role;

      if(this.userRole != Role.Lender) {
        this.fetchLenders();
      }
    });

    this.fetchNotifications();

    this.getNotification1MinInterval();

    this.reloadNotifications();

    this.fetchStates();

    this.fetchProviders();

    this.fetchAdministrators();

    this.fetchIndustries();

    this.fetchProducts();

  }

  private fetchStates() {
    this.statesService.get().subscribe((response) => {
      if (!response.isError) {
        this.statesList = response.data;
      }
    });
  }

  private fetchProviders() {
    this.providerService.get().subscribe((response) => {
      if (!response.isError) {
        this.providerList = response.data.filter((provider: any) => provider.isActive);
        this.providerList.unshift({ id: 0, name: 'All' });
      }
    });
  }
  private fetchAdministrators() {
    this.administratorService.get().subscribe((response) => {
      if (!response.isError) {
        this.administratorList = response.data;
        this.administratorList.unshift({ id: 0, name: 'All' });
      }
    });
  }
  private fetchIndustries() {
    this.industriesService.get().subscribe((response) => {
      if (!response.isError) {
        this.industryList = response.data;
        this.industryList.unshift({ id: 0, name: 'All' });
      }
    });
  }
  private fetchProducts() {
    this.productsService.get().subscribe((response) => {
      if (!response.isError) {
        this.productList = response.data;
        this.productList.unshift({ id: 0, name: 'All' });
      }
    });
  }
  private fetchLenders() {
    this.lendersService.get().subscribe((response) => {
      if (!response.isError) {
        this.lendersList = response.data.filter((lender: any) => lender.isActive);
      }
    });
  }

  onLogout() {
    this.authService.logout();
  }

  //Gets User object and returns firstName and lastName separate by " "
  getFullName(user: User | null | undefined): string | undefined {
    let fullName = '';
    if (user?.given_name && user.family_name) {
      fullName = `${user.given_name} ${user.family_name}`;
    }
    return fullName ? fullName : user?.nickname;
  }

  private _filter(value: string): Observable<ISearchResult[]> {
    if (!value) return of([]);

    const searchValue = value.toLowerCase();
    const filtersValue = this.filterGroup.value;

    return this.searchService.generalSearch({
      text: searchValue,
      status: filtersValue.status != 'All' ? filtersValue.status : undefined,
      providerOrganizationId: filtersValue.providerOrganizationId
        ? filtersValue.providerOrganizationId
        : undefined,
      statesIds:
          filtersValue.statesIds?.length > 0 ? filtersValue.statesIds.split(',') : null,
      lenderIds:
        filtersValue.lenderIds?.length > 0 ? filtersValue.lenderIds.split(',') : null,
      administratorId: filtersValue.administratorId
        ? filtersValue.administratorId
        : undefined,
      productId: filtersValue.productId ? filtersValue.productId : undefined,
      industryId: filtersValue.industryId ? filtersValue.industryId : undefined,
      fullSubmission: (filtersValue.submissionProgressId != null && filtersValue.submissionProgressId != 0) ? (filtersValue.submissionProgressId == 2) : undefined,
    });
  }

  hasMinLength(value: any) {
    return value ? value.length > 3 : false;
  }

  validateInput(value: any) {
    if (this.hasMinLength(value)) {
      return true;
    }
    this.filteredOptions = [];
    return false;
  }

  navigateItemTo(event: { id?: number; typeOfResult?: TypeOfResult }) {
    if (event.typeOfResult == TypeOfResult.FormSubmission) {
      this.onViewFormSubmission(event.id);
    } else if (event.typeOfResult == TypeOfResult.FormConsolidation) {
      this.onViewFormConsolidation(event.id);
    } else if (event.typeOfResult == TypeOfResult.MBRRequest) {
      this.onViewMBR(event.id);
    } else {
      this.onViewMarketingMaterial(event.id);
    }
  }

  markAsReadAndNavigate(
    notificationId?: number,
    referenceId?: number,
    entity?: TypeOfResult
  ) {
    this.notificationService.markAsRead([notificationId]).subscribe((_) => {
      this.notificationService.reloadBellNotifications.next(true);
      this.navigateItemTo({ id: referenceId, typeOfResult: entity });
    });
  }

  onViewFormSubmission(id?: number) {
    if (this.userRole === Role.Provider) {
      this.router.navigate([`/provider/form-submission/details/${id}`]);
    } else if (this.userRole === 'Lender') {
      this.router.navigate([`/lender/form-submission/details/${id}`]);
    } else {
      this.router.navigate([`/admin/form-submission/details/${id}`]);
    }
  }

  onViewMBR(id?: number) {
    if (this.userRole === Role.Lender) {
      this.router.navigate([`/lender/mbr-request/details/${id}`]);
    } else {
      this.router.navigate([`/admin/mbr-request/details/${id}`]);
    }
  }

  onViewFormConsolidation(id?: number) {
    if (this.userRole === Role.Provider) {
      this.router.navigate([`/provider/form-consolidation/details/${id}`]);
    } else {
      this.router.navigate([`/admin/form-consolidation/details/${id}`]);
    }
  }

  onViewMarketingMaterial(id?: number) {
    if (this.userRole === Role.Provider) {
      this.router.navigate([`/provider/marketing-material/details/${id}`]);
    } else if (this.userRole === 'Lender') {
      this.router.navigate([`/lender/marketing-material/details/${id}`]);
    } else {
      this.router.navigate([`/admin/marketing-material/details/${id}`]);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onNotificationPreferences() {
    this.openPreferences.emit(true);
  }

  private reloadNotifications() {
    this.notificationService.reloadBellNotifications.subscribe(() => {
      this.fetchNotifications();
    });
  }

  private getNotification1MinInterval() {
    this.subscription = interval(60000)
      .pipe(switchMap(() => this.notificationService.getLimited()))
      .subscribe((response) => {
        if (!response.isError) {
          this.notifications = response.data;
        }
      });
  }

  private fetchNotifications() {
    this.notificationService.getLimited().subscribe((response) => {
      if (!response.isError) {
        this.notifications = response.data;
      }
    });
  }

  applyFilter() {
    this.filterGroup.controls['text'].setValue(
      this.filterGroup.controls['text'].value
    );
  }
}
