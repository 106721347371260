import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ICellRendererParams,
  ColDef,
  GridReadyEvent,
} from 'ag-grid-community';
import { BtnCellRenderer } from 'src/app/components/btn-cell-renderer/btn-cell-renderer.component';
import {
  AgDateFilterParams,
  AgDateFormatter,
} from 'src/app/core/ag-date-formatter';
import { MBRRequestsService } from 'src/app/services/mbr-requests.service';
@Component({
  selector: 'app-mbr-request-list',
  templateUrl: './mbr-list.component.html',
  styleUrls: ['./mbr-list.component.scss'],
})
export class MBRRequestListComponent {
  isLoading: boolean = false;
  submissionService: any;

  constructor(private service: MBRRequestsService, private router: Router) {}
  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    {
      field: 'formNo',
      headerName: 'System No',
      flex: 2,
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      floatingFilter: true,
      sortable: true,
    },
    {
      field: 'product.name',
      headerName: 'Product',
      flex: 3,
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      floatingFilter: true,
      sortable: true,
    },
    {
      field: 'mbrRequestStatus.name',
      headerName: 'Status',
      flex: 3,
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      floatingFilter: true,
      sortable: true,
    },
    {
      field: 'dueDate',
      headerName: 'Review Deadline',
      flex: 3,
      valueFormatter: AgDateFormatter,
      filter: 'agDateColumnFilter',
      suppressMenu: true,
      floatingFilter: true,
      sortable: true,
      filterParams: AgDateFilterParams,
    },
    {
      field: 'action',
      width: 150,
      filter: false,
      sortable: false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onView: this.onView.bind(this),
      },
    },
  ];

  public rowData$!: any[];

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  onGridReady(params: GridReadyEvent) {
    this.getGridData();
  }

  private getGridData() {
    this.service.get().subscribe((r) => {
      this.rowData$ = r.data;
    });
  }

  onView(data: any) {
    this.router.navigate([`/lender/mbr-request-detail/${data.id}`]);
  }
}
