import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { APIResponseModel } from '../models/api-response-model';
import { Observable, catchError, map, shareReplay, take } from 'rxjs';
import { ORGANIZATION_ID_CLAIM, USER_ID_CLAIM, UserModel } from '../models/user/user.model';
import { AuthService } from '@auth0/auth0-angular';
import { ROLE_CLAIM, Role } from '../models/user/role.model';

export interface IUserNotificationPreference {
  modified: boolean,
  reachFinalState: boolean,
  frequency: boolean
}
@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseService {

  organizationId$: Observable<number>;
  userRole$: Observable<Role | undefined>;

  constructor(http: HttpClient, public authService: AuthService) {
    super(http, `users`);

    this.organizationId$ = this.getOrganizationId();
    this.userRole$ = this.getUserRole();
  }

  getUserRole(): Observable<Role | undefined> {
    return this.authService.user$.pipe(
      take(1),
      map(user => {
        return user ? user[ROLE_CLAIM] as Role : undefined;
      }),
      shareReplay(1)
    )
  }


  getOrganizationId(): Observable<number> {
    return this.authService.user$
      .pipe(
        take(1),
        map(user => {
          if (!user) {
            return 0;
          }
          const orgId = user[ORGANIZATION_ID_CLAIM];
          if (!orgId) {
            return 0;
          }
          return orgId;
        }),
        shareReplay(1)
      );
  }

  getUserId(): Observable<number> {
    return this.authService.user$
      .pipe(
        take(1),
        map(user => {
          if (!user) {
            return 0;
          }
          const userId = user[USER_ID_CLAIM];
          if (!userId) {
            return 0;
          }
          return userId;
        }),
        shareReplay(1)
      );
  }

  changeUserAvailability(id: string, enabled: boolean): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/change-availability`, { id, enabled: enabled })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

  getPreferences(): Observable<APIResponseModel<IUserNotificationPreference>> {
    return this.http
      .get<APIResponseModel<IUserNotificationPreference>>(
        `${this.baseUrl}/preferences`
      )
  }

  changeUserPreferences(preferences: IUserNotificationPreference): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/preferences`, { ...preferences })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }
}
