import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  static dueDateValidator(control: AbstractControl): ValidationErrors | null {
    const selectedDate = new Date(control.value);
    const currentDate = new Date();
    const twoWeeksInFuture = new Date(
      currentDate.getTime() + 14 * 24 * 60 * 60 * 1000
    );

    control.parent
      ?.get('intendedDateOfUse')
      ?.updateValueAndValidity({ emitEvent: true });

    if (selectedDate.getTime() >= twoWeeksInFuture.getTime()) {
      return null;
    }

    return { invalidDueDate: true };
  }

  static intendedDateOfUseValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    const selectedDate = new Date(control.value);
    const dueDate = control.parent?.get('dueDate')?.value;

    if (!dueDate) return null;

    return selectedDate.getTime() >= new Date(dueDate).getTime()
      ? null
      : { beforeDueDate: true };
  }

  static pastDateValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    if(!control.value){
      return null;
    }
    const selectedDate = new Date(control.value).setHours(0,0,0,0);

    const today = new Date().setHours(0,0,0,0);

    return selectedDate < today
      ? { pastDate: true }
      : null;
  }
}
