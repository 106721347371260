<div
  [ngClass]="
    isMulti ? 'custom-dropzone' : 'custom-dropzone custom-single-dropzone'
  "
  ngx-dropzone
  [accept]="'.pdf,.doc,.docx'"
  [maxFileSize]="MAX_SIZE"
  (change)="onSelect($event)"
  [disabled]="disabled"
  [multiple]="isMulti"
>
  <ngx-dropzone-label>
    <div class="drag-dorp-label">
      <div class="icon-upload">
        <mat-icon fontSet="material-icons-outlined">file_upload</mat-icon>
      </div>

      <span class="upload-inst"
        ><strong>Click to upload </strong>or drag and drop</span
      >
      <span class="upload-size">PDF, JPG, DOCX (max. 50MB)</span>
    </div>
  </ngx-dropzone-label>
  <ngx-dropzone-image-preview
    ngProjectAs="ngx-dropzone-preview"
    *ngFor="let fileDetails of files"
    [file]="fileDetails.file"
    [removable]="true"
    (removed)="onRemove(fileDetails)"
    [ngClass]="getFileType(fileDetails.file.type)"
  >
    <ngx-dropzone-label [ngClass]="getFileType(fileDetails.file.type)">
      <mat-icon fontSet="material-icons-outlined">insert_drive_file</mat-icon>{{ fileDetails.file.name }}
      <i
        *ngIf="fileDetails.uploadStatus == uploadStatus.PENDING"
        class="fa fa-spinner fa-spin"
      ></i
    ></ngx-dropzone-label>
  </ngx-dropzone-image-preview>

</div>
