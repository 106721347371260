import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  appTooltip: string | null = null;
  left: number = 0;
  top: number = 0;

  constructor() {}

  ngOnInit(): void {}
}
