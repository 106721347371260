import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
  standalone: true
})
export class InitialsPipe implements PipeTransform {
  transform(value?: string, separator: string = ' ', limit = 2): string {
    if (!value) return '';

    const words = value.split(separator);

    const initials = words.map(word => word.charAt(0)).slice(0,limit);

    return initials.join('');
  }
}
