import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Observable, map, startWith, take, tap } from 'rxjs';
import { MatSelectAllComponent } from './mat-select-all/mat-select-all.component';

export interface ISelect {
  id: number;
  name: string;
}
@Component({
  selector: 'app-mat-multi-select',
  templateUrl: './mat-multiselect.component.html',
  styleUrls: ['./mat-multiselect.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatSelectAllComponent,
  ],
})
export class MatMultiSelectComponent {
  @ViewChild('search') searchTextBox: ElementRef;
  @Input('control') selectFormControl: AbstractControl;
  @Input() options: ISelect[];
  @Input() disabled: boolean = false;
  @Input() requiredMessage: string;
  @Input() innerLabel: string;
  @Input() size: string = 'lg';

  searchTextboxControl = new FormControl();
  selectedValues: ISelect[] = [];
  filteredOptions: Observable<ISelect[]>;

  ngOnInit() {
    this.filteredOptions = this.searchTextboxControl.valueChanges.pipe(
      startWith<string>(''),
      map((name) => this._filter(name))
    );

  }

  getFormControl(): FormControl {
    return this.selectFormControl as FormControl;
  }

  private _filter(name: string): ISelect[] {
    const filterValue = name.toLowerCase();

    let filteredList = this.options.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
    return filteredList;
  }

  openedChange(e: any) {
    this.searchTextboxControl.patchValue('');
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  setSelectedValues() {
    if (
      this.selectFormControl.value &&
      this.selectFormControl.value.length > 0
    ) {
      this.selectFormControl.value.forEach((e: any) => {
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
        }
      });
    }
    else {
      this.selectedValues = [];
    }
  }

  getValuesSample() {
    return this.selectFormControl.value
      ? this.selectFormControl.value
          .slice(0, 3)
          .map((value: any) => value.name)
          .join(', ')
      : '';
  }
}
