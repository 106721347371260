<div class="consolidation-data-card">
    <table class="table table-bordered" *ngIf="organizationData">
        <tbody>
            <tr>
                <td><h3 class="data-value">{{ organizationData.name }}</h3></td>
            </tr>
            <tr>
                <td><span class="data-value">{{ organizationData.description }}</span></td>
            </tr>
            <tr>
                <td><span class="data-value">Code: {{ organizationData.code }}</span></td>
            </tr>
        </tbody>
    </table>
</div>