import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { take } from 'rxjs';
import { ROLE_CLAIM, Role } from 'src/app/models/user/role.model';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  constructor(private router: Router,
     private auth: AuthenticationService,
    ) {}

  ngOnInit(): void {
    this.auth.user$
    .pipe(take(1))
    .subscribe(user => {
      if(!user)
      {
        this.auth.login();
        return;
      }

      const role: Role | undefined = user[ROLE_CLAIM];
      
      this.redirect(role);
    });
  }

  redirect(role: Role | undefined){
    switch(role){
      case Role.Admin:
      case Role.SystemUser:
        this.router.navigate(['admin/dashboard']);
        break;
      case Role.Provider:
        this.router.navigate(['provider/dashboard']);
        break;
      case Role.Lender:
        this.router.navigate(['lender/dashboard']);
        break;
      default:
        this.auth.logout();
        break;
    }
  }

}
