import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-badge-column',
  templateUrl: './grid-badge-column.component.html',
  styleUrls: ['./grid-badge-column.component.scss']
})
export class GridBadgeColumnComponent implements ICellRendererAngularComp
{

  refresh(params: ICellRendererParams<any, any, any>): boolean {
      return false;
  }

  agInit(params: any): void {
      this.badges = params.getValue();
  }

  public badges: any;
}