export const environment: {
  baseUrl?: string;
  production: boolean;
  demo: boolean;
  auth0: {
    domain: string,
    clientId: string,
    audience: string
  };
} = {
  baseUrl: 'https://api.dev.portal.proteriscompliance.com',
  production: false,
  demo: false,
  auth0: {
    domain: 'dev.auth.portal.proteriscompliance.com',
    clientId: 'v5H6vMhhNtEfdlfzbv9BAF0Z61rsdxFw',
    audience: 'https://dev-proteris-api/'
  }
};
