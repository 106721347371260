<div class="pcs-container">
  <div class="pcs-header">
    <h3 class="pcs-title">Lenders List</h3>
    <div class="card-actions"></div>
  </div>

  <div class="pro-card">
    <app-ag-grid-wrapper
    [data]="rowData$"
    [columns]="columnDefs"
    (ready)="onGridReady($event)"
  />
  </div>
</div>
