import { Component, EventEmitter, Output } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'btn-cell-renderer',
    template: `
    <div class="btn-col">
      <button *ngIf="params.onEdit" (click)="onEditClick()" class="btn btn-outline-primary w-32"><i class="fal fa-pen"></i></button>
      <button *ngIf="params.onDuplicate" (click)="onDuplicateClick()" class="btn btn-outline-primary w-32"><i class="far fa-copy"></i></button>
      <button *ngIf="params.onView" (click)="onViewClick()" class="btn btn-outline-primary w-32"><i class="fal fa-eye"></i></button>
      <button *ngIf="params.onDelete" (click)="onDeleteClick()" class="btn btn-outline-danger w-32"><i class="fal fa-trash-alt"></i></button>
      <button *ngIf="params.onChangeAvailability" (click)="onChangeAvailabilityClick()"
        [class]="params.data.isActive ? 'btn btn-outline-danger w-32':'btn btn-outline-success w-32'">
        <i [class]="params.data.isActive ? 'fas fa-times' : 'fas fa-check'"></i>
      </button>
      <button *ngIf="params.getValue() !== undefined ? params.getValue().onConfirmation : undefined " (click)="onConfirmationClick()" class="btn"><i class="fal fa-check-square"></i></button>
    </div>
    `,
  })
  export class BtnCellRenderer implements ICellRendererAngularComp
  {

    refresh(params: ICellRendererParams<any, any, any>): boolean {
        return false;
    }

    public params: any;
    agInit(params: any): void {
        this.params = params;
    }

    onEditClick(): void {
        this.params.onEdit(this.params.data);
    }

    onViewClick(): void {
        this.params.onView(this.params.data);
    }
    onDuplicateClick(): void {
        this.params.onDuplicate(this.params.data);
    }

    onDeleteClick(): void {
      this.params.onDelete(this.params.data);
    }
    onChangeAvailabilityClick(): void {
        this.params.onChangeAvailability(this.params.data);
    }

    onDisableClick(): void {
      this.params.onDisable(this.params.data);
    }

    onConfirmationClick(): void {
        this.params.getValue().onConfirmation(this.params.getValue());
    }
  }
