<form class="search-form">
  <mat-form-field
    class="search-field custom-field no-hint"
    appearance="fill"
    [ngClass]="{
      expanded: showOptionsPanel(),
      menu: isMenuOpened()
    }"
  >
    <input
      type="text"
      placeholder="Press Enter for quick search"
      aria-label="Search"
      matInput
      #searchTrigger="matAutocompleteTrigger"
      [formControl]="getSearchControl()"
      [matAutocomplete]="auto"
      (keyup.enter)="onPressEnter()"
    />
    <mat-icon color="primary" matPrefix>search</mat-icon>
    <button
      [type]="'button'"
      [ngClass]="{ hide: loading }"
      mat-icon-button
      matSuffix
      #filterTrigger="matMenuTrigger"
      [matMenuTriggerFor]="filterMenu"
      (menuOpened)="isMenuOpen = true"
      (menuClosed)="isMenuOpen = false"
      (click)="$event.stopPropagation()"
    >
      <mat-icon color="primary">filter_list</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option class="option-no-opacity" disabled *ngIf="showOptionsPanel()">
        <span
          >{{ getMostRecentResultFor()
          }}<strong>"{{ getSearchValue() }}"</strong></span
        >
      </mat-option>
      <mat-option
        *ngFor="let option of options"
        [value]="option.systemNo"
        (click)="navigateItemTo(option.id, option.typeOfResult)"
      >
        <div class="item-result">
          <div class="item-name">
            <span class="material-icons-outlined icon-primary"
              >insert_drive_file</span
            >
            {{ option.systemNo }}
          </div>
          <span class="item-type">{{
            getResultTypeText(option.typeOfResult)
          }}</span>
        </div>
      </mat-option>
      <mat-option
        class="option-no-opacity bg-gray"
        disabled
        *ngIf="showOptionsPanel()"
      >
        <a
          (click)="goToSearchView()"
          class="footer-action"
          routerLinkActive="router-link-active"
          >View al results</a
        >
      </mat-option>
    </mat-autocomplete>
    <app-loader class="loader-container" *ngIf="loading" matSuffix></app-loader>
  </mat-form-field>
  <mat-menu
    #filterMenu="matMenu"
    [class]="'custom-menu'"
    [overlapTrigger]="false"
    [xPosition]="'before'"
  >
    <div class="filter-menu-container" [formGroup]="filterGroup">
      <div class="flex-header">
        <h6>Filter By</h6>
        <button mat-button color="primary" (click)="onClearFilters($event)">Clear</button>
      </div>

      <div class="form-row">
        <div class="custom-form-field" *ngIf="role !== 'Provider'">
          <label for="providerOrganizationId">Provider</label>
          <mat-form-field
            appearance="outline"
            class="custom-field sm"
            (click)="$event.stopPropagation()"
          >
            <mat-select formControlName="providerOrganizationId" name="providerOrganizationId">
              <mat-option
                *ngFor="let provider of providers"
                [value]="provider.id"
                >{{ provider.name }}</mat-option
              >
            </mat-select>
            <mat-icon matSuffix>expand_more</mat-icon>
          </mat-form-field>
        </div>
        <div class="custom-form-field">
          <label for="administratorId">Administrator</label>
          <mat-form-field appearance="outline" class="custom-field sm" (click)="$event.stopPropagation()">
            <mat-select
              formControlName="administratorId"
              name="administratorId"
            >
              <mat-option
                *ngFor="let administrator of administrators"
                [value]="administrator.id"
                >{{ administrator.name }}</mat-option
              >
            </mat-select>
            <mat-icon matSuffix>expand_more</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="custom-form-field">
          <label for="productId">Product</label>
          <mat-form-field appearance="outline" class="custom-field sm" (click)="$event.stopPropagation()">
            <mat-select formControlName="productId" name="productId">
              <mat-option
                *ngFor="let product of products"
                [value]="product.id"
                >{{ product.name }}</mat-option
              >
            </mat-select>
            <mat-icon matSuffix>expand_more</mat-icon>
          </mat-form-field>
        </div>
        <ng-container *ngIf="role !== 'Lender'">
          <div
            class="custom-form-field"
            *ngIf="lenders.length > 0; else emptyLenders"
          >
            <label for="lenderIds">Lenders</label>
            <app-mat-multi-select
              [size]="'sm'"
              [requiredMessage]="'Please select Lenders'"
              [options]="lenders"
              [control]="filterGroup.controls['lenderIds']"
              (click)="$event.stopPropagation()"
            />
          </div>
          <ng-template #emptyLenders>
            <div class="custom-form-field w-100">
              <label for="emptyLenders">Lenders</label>
              <mat-form-field appearance="outline" class="custom-field sm">
                <input
                  [disabled]="true"
                  placeholder="Loading"
                  matInput
                  name="emptyLenders"
                />
              </mat-form-field>
            </div>
          </ng-template>
        </ng-container>
      </div>
      <div class="form-row">
        <div class="custom-form-field">
          <label for="statusId">Status</label>
          <mat-form-field
            appearance="outline"
            class="custom-field sm"
            (click)="$event.stopPropagation()"
          >
            <mat-select
              formControlName="status"
              name="status"
            >
              <mat-option
                *ngFor="let status of statusList"
                [value]="status.id"
                >{{ status.name }}</mat-option
              >
            </mat-select>
            <mat-icon matSuffix>expand_more</mat-icon>
          </mat-form-field>
        </div>
        <div class="custom-form-field">
          <label for="submissionProgressId">Submission Progress</label>
          <mat-form-field
            appearance="outline"
            class="custom-field sm"
            (click)="$event.stopPropagation()"
          >
            <mat-select
              formControlName="submissionProgressId"
              name="submissionProgressId"
            >
              <mat-option
                *ngFor="let submissionProgress of submissionProgressList"
                [value]="submissionProgress.id"
                >{{ submissionProgress.name }}</mat-option
              >
            </mat-select>
            <mat-icon matSuffix>expand_more</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="custom-form-field">
          <label for="industryId">Industry</label>
          <mat-form-field appearance="outline" class="custom-field sm" (click)="$event.stopPropagation()">
            <mat-select formControlName="industryId" name="industryId">
              <mat-option
                *ngFor="let industry of industries"
                [value]="industry.id"
                >{{ industry.name }}</mat-option
              >
            </mat-select>
            <mat-icon matSuffix>expand_more</mat-icon>
          </mat-form-field>
        </div>
        <div
          class="custom-form-field"
          *ngIf="states.length > 0; else emptyStates"
        >
          <label for="statesIds">States</label>
          <app-mat-multi-select
            [size]="'sm'"
            [requiredMessage]="'Please select States'"
            [options]="states"
            [control]="filterGroup.controls['statesIds']"
            (click)="$event.stopPropagation()"
          />
        </div>
        <ng-template #emptyStates>
          <div class="custom-form-field w-100">
            <label for="emptyStates">States</label>
            <mat-form-field appearance="outline" class="custom-field sm">
              <input
                [disabled]="true"
                placeholder="Loading"
                matInput
                name="emptyStates"
              />
            </mat-form-field>
          </div>
        </ng-template>
      </div>
      <div class="form-row">
        <button
          (click)="onApplyFilter()"
          mat-flat-button
          color="primary"
          type="button"
          class="apply-button"
          >
            Apply
          </button>
      </div>
    </div>
  </mat-menu>
  <mat-icon
    class="info-icon"
    matTooltip="Please enter at least 4 characters."
    matTooltipPosition="below"
    >info_outlined</mat-icon
  >
</form>
