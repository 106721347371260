export const ORGANIZATION_ID_CLAIM: string = "proterisOrganizationId"
export const USER_ID_CLAIM: string = "proterisUserId"

export class UserModel {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    token: string;
    requirePasswordReset: boolean;
    organization: string;
    products: string[];
    roles: string[];
    permissions: string[];

}