<div
  class="toast-container"
  [ngClass]="{
    success: data.status == 'success',
    error: data.status == 'error'
  }"
>
  <div class="toast-icon">
    <span class="icon material-icons">{{
      data.status == "success" ? "check_circle" : "error"
    }}</span>
  </div>
  <div class="toast-right">
    <div class="toast-title">{{ data.title }}</div>
    <div class="toast-content">{{ data.content }}</div>
  </div>
</div>
