import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { IProduct } from 'src/app/models/product/product.model';
import { IProviderOrganizationDocument, IProviderOrganizationDocumentSubType, IProviderOrganizationDocumentType, IProviderOrganizationFile, ProviderOrganizationDocumentSubType, ProviderOrganizationDocumentType, ProviderOrganizationFileType } from 'src/app/models/provider/provider-license.model';
import { IState } from 'src/app/models/state/state.model';
import { DocumentTypesService } from 'src/app/services/document-types.service';
import { UploadDocumentsComponent } from '../upload-documents/upload-documents.component';
import { DocumentsService } from 'src/app/services/documents.service';
import { ProviderDocumentService } from 'src/app/services/provider.document.service';
import { MatRadioChange } from '@angular/material/radio';
import { CommonService } from 'src/app/services/common.service';
import { DeleteConfirmationDialogService } from 'src/app/services/delete-confirmation-dialog.service';
import { DocumentModel, UPLOADSTATUS } from 'src/app/models/document/document.model';

@Component({
  selector: 'app-provider-documents',
  templateUrl: './provider-documents.component.html',
  styleUrls: ['./provider-documents.component.scss']
})
export class ProviderDocumentsComponent implements OnInit{
  ProviderOrganizationFileType = ProviderOrganizationFileType;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('documentFiles') documentFiles: UploadDocumentsComponent;
  @ViewChild('filingProofFiles') filingProofFiles: UploadDocumentsComponent;

  @Input() organizationId: string;
  @Input() states: IState[] = [];
  @Input() products: IProduct[] = [];
  @Input() documentType: IProviderOrganizationDocumentType;
  @Input() pageSettings: {
    defaultPageSize: number,
    pageSizeOptions: number[],
  } = {
    defaultPageSize: 5,
    pageSizeOptions: [5,10,15]
  }

  public documentForm: FormGroup;

  public isLoadingLicenses: boolean = false;
  public isLoading: boolean = false;

  public currentPage: number = 0;
  public pageSize: number = this.pageSettings.defaultPageSize;
  
  public providerLicenses: IProviderOrganizationDocument[] = [];
  public licenseToUpdate: IProviderOrganizationDocument | null;
  copyFilesToUpdate: any[] = [];
  filingProofFilesToUpdate: any[] = [];

  public filterForm = new FormGroup({
    searchText: new FormControl(''),
    documentSubTypeId: new FormControl(0),
    stateIds: new FormControl<IState[]>([]),
    productIds: new FormControl<IProduct[]>([]),
    expirationDate: new FormControl(null)
  });

  get providerLicensesFiltered(){
    return this.applyFilters();
  }

  get providerLicensesToShow(){
    const startIndex = this.currentPage * this.pageSize;
    return this.providerLicensesFiltered.slice(startIndex, startIndex + this.pageSize);
  }
  
  public licenseActions = [
    {
      icon: 'delete',
      cssClass: 'danger outer icon-only',
      click: this.deleteLicense.bind(this)
    },
    {
      icon: 'download',
      cssClass: 'primary outer icon-only',
      click: this.downloadLicense.bind(this)
    },
    {
      icon: 'upload_file_outlined',
      cssClass: 'primary icon-only fill',
      click: this.uploadLicense.bind(this)
    }
  ]

  constructor(private providerOrganizationsService: ProviderDocumentService, 
      private documentService: DocumentsService,
      public _MatPaginatorIntl: MatPaginatorIntl,
      private cdr: ChangeDetectorRef,
      private deleteDialog: DeleteConfirmationDialogService
  ) {
    
  }

  ngOnInit(): void {
    this._MatPaginatorIntl.itemsPerPageLabel = 'Show items per page';
    this.initializeDocumentForm();
    this.loadProviderLicenses();
  }

  initializeDocumentForm(){
    this.documentForm = new FormGroup({
      documentSubTypeId: new FormControl('', [Validators.required]),
      productIds: new FormControl([]),
      stateIds: new FormControl([]),
      expirationDate: new FormControl('', [CommonService.pastDateValidator]),
      documentFiles: new FormControl<IProviderOrganizationFile[]>([]),
      filingProofFiles: new FormControl<IProviderOrganizationFile[]>([]),
    });

    this.documentForm.get('productIds')!.addValidators(this.customRequiredValidator(this.documentForm));
    this.documentForm.get('stateIds')!.addValidators(this.customRequiredValidator(this.documentForm));
    this.documentForm.get('expirationDate')!.addValidators(this.customRequiredValidator(this.documentForm));
    this.documentForm.get('documentFiles')!.addValidators(this.customRequiredValidator(this.documentForm));
    this.documentForm.updateValueAndValidity();
  }

  private loadProviderLicenses() {
    this.isLoadingLicenses = true;
    this.providerOrganizationsService
      .get({ 
        organizationId: this.organizationId, 
        documentTypeId: this.documentType.id 
      })
      .subscribe(response => {
        this.isLoadingLicenses = false;
        if(!response.isError){
          this.providerLicenses = response.data;
        }
      });
  }

  closeRightSidebar(){
    this.resetFormValues();
    this.licenseToUpdate = null;
    this.copyFilesToUpdate = [];
    this.filingProofFilesToUpdate = [];
    $('.license-sidebar').removeClass('open');
  }

  resetFormValues() {
    this.documentForm.reset();
    this.documentFiles.clearFiles();
    this.filingProofFiles?.clearFiles();
  }

  openRightSidebar(id: string | null = null) {
    $('.license-sidebar').toggleClass('open');
  }

  getSidebarTitle(): string{
    switch(this.documentType.id){
      case ProviderOrganizationDocumentType.LICENSES_PERMITS:
        return "Upload new License or Permit";
      case ProviderOrganizationDocumentType.FINANCIAL_SECURITY:
        return "Upload new Financial Security Doc";
      default:
        return "Upload new Document"
    }
  }

  getSubtypeName(subType: IProviderOrganizationDocumentSubType): string{
    if(this.documentType.id === ProviderOrganizationDocumentType.FINANCIAL_SECURITY &&
       subType.name.toLowerCase().startsWith('other')){
        return "Other (Bond, Proof of Funded Reserve, etc.)";
    }

    return subType.name;
  }

  getDataToSubmit(){
    const data = {
      documentSubTypeId: this.documentForm.value.documentSubTypeId,
      productIds: this.documentForm.value.productIds.map((p: any) => p.id),
      stateIds: this.documentForm.value.stateIds.map((s: any) => s.id),
      expirationDate: this.documentForm.value.expirationDate,
      documentFiles: this.documentForm.value.documentFiles,
    } 
    const filingProofFiles = this.documentForm.value.filingProofFiles;

    if(filingProofFiles && filingProofFiles.length > 0){
      data.documentFiles = data.documentFiles.concat(filingProofFiles);
    }

    return data;
  }

  onSubmit(){
    if(this.documentForm.valid) {
      const formData = this.getDataToSubmit();
      this.isLoading = true;
      if(this.licenseToUpdate){
        this.providerOrganizationsService
        .update(`${this.licenseToUpdate.id}`, formData)
        .subscribe(response => {
          this.isLoading = false;
          if(response && response.isError){
            return;
          } 
          this.closeRightSidebar();
          this.loadProviderLicenses();
        });  
      }
      else{
        const data = {
          ...formData,
          organizationId: this.organizationId,
          documentTypeId: this.documentType.id
        }
        this.providerOrganizationsService
        .add(data)
        .subscribe(response => {
          this.isLoading = false;
          if(!response.isError){
            this.closeRightSidebar();
            this.loadProviderLicenses();
          }
        }); 
      }
    }
  }

  onFilingProofRemove(fileCount: number){
    this.documentForm.get('filingProofFiles')?.setValue(this.getFilingProofFiles());
    this.documentForm.updateValueAndValidity();
  }

  uploadingFilingProofEventHandler(status: boolean){
    this.isLoading = status;
    if (this.filingProofFiles.uploadedFiles().length > 0) {
      this.documentForm
        .get('filingProofFiles')
        ?.setValue(this.getFilingProofFiles());
    }
  }

  uploadingLicenseCopyEventHandler(status: boolean){
    this.isLoading = status;
    if (this.documentFiles.uploadedFiles().length > 0) {
      this.documentForm
        .get('documentFiles')
        ?.setValue(this.getDocumentFiles());
    }
  }

  getDocumentFiles(){
    return this.documentFiles.uploadedFiles().map(d => ({...d, fileType: ProviderOrganizationFileType.COPY}));
  }

  getFilingProofFiles(){
    return this.filingProofFiles.uploadedFiles().map(d => ({...d, fileType: ProviderOrganizationFileType.FILING_PROOF}));
  }

  getFileUploadDescription(): string{
    switch(this.documentType.id){
      case ProviderOrganizationDocumentType.LICENSES_PERMITS:
        return "Upload a copy of the license:";
      case ProviderOrganizationDocumentType.FINANCIAL_SECURITY:
        return this.isSubtypeSelected(ProviderOrganizationDocumentSubType.AM_BEST_RATING) ? 
          "Upload documentation of underwriter’s or risk retention group’s A.M. Best rating:"
          : "Upload documentation of financial security requirement:"
      default:
        return "Upload document";
    }
  }

  isDocumentType(documentType: ProviderOrganizationDocumentType): boolean{
    return this.documentType.id === documentType;
  }

  isSubtypeSelected(subtype: ProviderOrganizationDocumentSubType): boolean{
    return this.documentForm.value.documentSubTypeId === subtype;
  }

  showFilingProofDropzone(): boolean{
    return this.isDocumentType(ProviderOrganizationDocumentType.FINANCIAL_SECURITY) 
    && !this.isSubtypeSelected(ProviderOrganizationDocumentSubType.AM_BEST_RATING);
  }

  showApplicableStates(): boolean{
    return !this.isSubtypeSelected(ProviderOrganizationDocumentSubType.AM_BEST_RATING);
  }

  showProducts(): boolean{
    return !this.isSubtypeSelected(ProviderOrganizationDocumentSubType.AM_BEST_RATING);
  }

  showExpirationDate(): boolean{
    return !this.isSubtypeSelected(ProviderOrganizationDocumentSubType.AM_BEST_RATING);
  }

  handlePageEvent(event: PageEvent){
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  onChangeFormSubtype(event: MatRadioChange){
    if(event.value === ProviderOrganizationDocumentSubType.AM_BEST_RATING){
      this.documentForm.get('productIds')?.setValue([]);
      this.documentForm.get('stateIds')?.setValue([]);
      this.documentForm.get('expirationDate')?.setValue(null);
      this.documentForm.get('filingProofFiles')?.setValue([]);
      this.filingProofFiles?.clearFiles();
    }
    this.cdr.detectChanges();
  }

  onLicenseCopyRemove(fileCount: number){
    this.documentForm.get('documentFiles')?.setValue(this.getDocumentFiles());
    this.documentForm.updateValueAndValidity();
  }

  onDeleteConfirmation(document: IProviderOrganizationDocument){
    this.isLoadingLicenses = true;
    this.providerOrganizationsService
      .delete(`${document.id}`)
      .subscribe(
        _ => this.loadProviderLicenses()
      );
  }

  deleteLicense(document: IProviderOrganizationDocument): void{
    //TODO: call confirmation popup
    this.deleteDialog.openDeleteConfirmation("This document will be removed from the system.")
      .afterClosed()
      .subscribe(deleteConfirmation => {
        if(deleteConfirmation){
          this.onDeleteConfirmation(document);
        }
      });
  }

  downloadLicense(license: IProviderOrganizationDocument): void{
    license.documentFiles.forEach(lc => {
      this.documentService.downloadDocument({
        name: lc.name,
        serverFileName: lc.systemName
      });
    });
  }

  uploadLicense(license: IProviderOrganizationDocument): void{
    this.licenseToUpdate = license;
    this.documentForm.get('documentSubTypeId')?.setValue(license.documentSubType.id);
    this.documentForm.get('productIds')?.setValue(this.products.filter(p => license.products.some(_p => _p.id === p.id)));
    this.documentForm.get('stateIds')?.setValue(this.states.filter(s => license.applicableStates.some(_s => _s.id === s.id)));
    this.documentForm.get('expirationDate')?.setValue(license.expirationDate);

    this.copyFilesToUpdate = this.licenseToUpdate?.documentFiles
    .filter(df => df.fileType === ProviderOrganizationFileType.COPY)
    .map(file => {
      const document =  new DocumentModel(
        new File([new Blob], file.name),
        UPLOADSTATUS.SUCCESS
      );

      document.serverFileName = file.systemName;
      return document;
    }) ?? [];
    
    this.filingProofFilesToUpdate = this.licenseToUpdate?.documentFiles
    .filter(df => df.fileType === ProviderOrganizationFileType.FILING_PROOF)
    .map(file => {
      const document =  new DocumentModel(
        new File([new Blob], file.name),
        UPLOADSTATUS.SUCCESS
      );

      document.serverFileName = file.systemName;
      return document;
    }) ?? [];



    this.documentForm.get('documentFiles')?.setValue(
      [...this.copyFilesToUpdate.map(f => ({name: f.file.name, serverFileName: f.serverFileName})),
      ...this.filingProofFilesToUpdate.map(f => ({name: f.file.name, serverFileName: f.serverFileName}))
    ]);

    this.openRightSidebar();
  }

  applyFilters(): IProviderOrganizationDocument[] {
    const filters = this.filterForm.value;

    let filteredLicenses = this.providerLicenses;

    if(filters.documentSubTypeId != 0){
      filteredLicenses = filteredLicenses.filter(license =>
        license.documentSubType.id === filters.documentSubTypeId);
    }

    if (filters.searchText) {
      filteredLicenses = filteredLicenses.filter(license =>
        license.documentFiles.some(product => product.name.toLowerCase().includes(filters.searchText!.toLowerCase()))
      );
    }

    if (filters.stateIds && filters.stateIds.length > 0) {
      filteredLicenses = filteredLicenses.filter(license =>
        filters.stateIds!.every(s => license.applicableStates.some(_s => s.id === _s.id))
      );
    }

    if (filters.productIds && filters.productIds.length > 0) {
      filteredLicenses = filteredLicenses.filter(license =>
        filters.productIds!.every(p => license.products.some(_p => p.id === _p.id))
      );
    }

    if (filters.expirationDate) {
      const filterDate = new Date(filters.expirationDate).setHours(0, 0, 0, 0); //normalize to midnight
      filteredLicenses = filteredLicenses.filter(license => {
        const licenseDate = new Date(license.expirationDate).setHours(0, 0, 0, 0);
        return licenseDate === filterDate;
      });
    }

    return filteredLicenses;
  }

  customRequiredValidator(form: FormGroup): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const needRequire = form.get('documentSubTypeId')?.value != ProviderOrganizationDocumentSubType.AM_BEST_RATING
      const noValue = needRequire ? (!(control.value) || control.value?.length === 0) : false;
      return noValue ? {required: noValue} : null;
    };
  }

  getProviderDocumentsToUpdate(fileType: ProviderOrganizationFileType){ 
  }
}
