
import { AgGridModule } from 'ag-grid-angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProviderRoutingModule } from './provider-routing.module';

// Provider components
import { ProductMbrListComponent } from './components/mbr-list/mbr-list.component';
import { SharedModule } from '../shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LendersOrganizationListComponent } from './components/lenders-organization-list/lenders-organization-list.component';
import { OrganizationDetailsComponent } from './components/organization-details/organization-details.component';


@NgModule({
  declarations: [
    ProductMbrListComponent,
    LendersOrganizationListComponent,
    OrganizationDetailsComponent

  ],
  imports: [
    CommonModule,
    ProviderRoutingModule,
    AgGridModule,
    NgxDropzoneModule,
    NgSelectModule, FormsModule, ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    SharedModule,
  ]
})
export class ProviderModule { }
