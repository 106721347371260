import { Injectable, NgZone } from '@angular/core';
import { map, Observable, of, shareReplay, Subject, Subscription, take, takeWhile, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { APIResponseModel } from '../models/api-response-model';
import { UserModel } from '../models/user/user.model';
import { UsersService } from './users.service';
import { AuthService, User } from '@auth0/auth0-angular';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GeneralDialogComponent } from '../modules/shared/components/general-dialog/general-dialog.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  user$: Observable<User | null | undefined>;
  constructor(private auth: AuthService, private router: Router) {
    this.user$ = this.auth.user$.pipe(
      take(1),
      shareReplay(1)
    );
    this.auth.error$.subscribe(error => {
      if(error.message.startsWith('Access denied')) {
        this.router.navigate(['access-denied'])
      }
    });
  }

  logout(){
    this.auth.logout();
  }

  login(){
    this.auth.loginWithRedirect();
  }
}
