import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, tap } from 'rxjs';
import { ISearchResult } from '../modules/shared/components/main-navigation/search.model';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';

export interface ISearchFilter {
  text?: string;
  lenderIds?: number[];
  status?: string;
  providerOrganizationId?: number;
  administratorId?: number;
  productId?: number;
  industryId?: number;
  fullSubmission?: boolean;
  statesIds?: number[];
}

export interface IExportFilter {
  searchFilters: ISearchFilter,
  formSubmission: boolean,
  formConsolidation: boolean,
  mbrRequest: boolean,
  marketingMaterial: boolean
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  domain: any;
  baseUrl: string;

  private httpClient: HttpClient;

  constructor(private http: HttpClient, private toastService: ToastService) {
    this.domain =
      environment.baseUrl ?? location.origin.replace('portal', 'api');
    this.baseUrl = [this.domain, 'api', 'search'].join('/');
  }

  generalSearch(
    filter: ISearchFilter = {},
    formType?: string
  ): Observable<ISearchResult[] | any> {
    const url = formType ? `${this.baseUrl}/${formType}` : this.baseUrl;

    return this.http.post<ISearchResult[] | any>(url, { ...filter });
  }

  exportSearch(exportFilter: IExportFilter) {
    const url = `${this.baseUrl}/export`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    return this.http.post<Blob>(url, { ...exportFilter }, { headers, responseType: 'blob' as 'json' })
      .pipe(
        tap((res: Blob) => {
          const date = new Date();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const year = date.getFullYear();
          const fileName = `search_result_${month}${day}${year}.xlsx`;

          const fileUrl = URL.createObjectURL(res);
          const a = document.createElement('a');
          a.href = fileUrl;
          a.download = fileName;
          a.click();
          URL.revokeObjectURL(fileUrl);

          this.toastService.success('Documents', 'Were successfully exported');
        })
      );
  }

}
