import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class IndustriesService extends BaseService {

  private apiUrl = `industries`;
  private httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(http, `industries`);
  }
}

