<div class="pcs-container">
  <div class="pcs-header">
    <h3 class="pcs-title">Notifications</h3>
    <div class="card-actions">
      <button
        mat-flat-button
        color="primary"
        type="button"
        [disabled]="countSelected == 0"
        [matBadge]="countSelected"
        [matBadgeSize]="'small'"
        [matBadgeColor]="'warn'"
        (click)="onMarkAsRead()"
        >Mark as read</button

      >
    </div>
  </div>
  <div class="pro-card">
    <app-ag-grid-wrapper
      [data]="rowData$"
      [columns]="columnDefs"
      [rowSelectionMode]="'multiple'"
      (ready)="onGridReady($event)"
      (selection)="onSelection($event)"
    />
  </div>
</div>
