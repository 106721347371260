import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { APIResponseModel } from '../models/api-response-model';
import { catchError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProviderOrganizationsService extends BaseService {

  private apiUrl = `providerorganizations`;
  private httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(http, `providerorganizations`);
  }

  changeUserAvailability(id: string, enabled: boolean): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/change-availability`, { id, enabled: enabled })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }
}
