export interface IProviderOrganizationDocument {
    id: number
    documentType: IProviderOrganizationDocumentType
    documentSubType: IProviderOrganizationDocumentSubType
    applicableStates: IProviderOrganizationDocumentState[]
    products: IProviderOrganizationDocumentProduct[]
    expirationDate: Date
    documentFiles: IProviderOrganizationFile[]
    isActive: boolean
}

export interface IProviderOrganizationDocumentType{
    id: number
    name: string
    order: number
    documentSubTypes: IProviderOrganizationDocumentSubType[]
}

export enum ProviderOrganizationDocumentType{
    LICENSES_PERMITS = 1,
    FINANCIAL_SECURITY = 2
}

export interface IProviderOrganizationDocumentSubType{
    id: number
    name: string
    order: number
}

export enum ProviderOrganizationDocumentSubType{
    AM_BEST_RATING = 6
}

export interface IProviderOrganizationDocumentState{
    id: number
    name: string
}

export interface IProviderOrganizationDocumentProduct{
    id: number
    name: string
}

export interface IProviderOrganizationFile{
    id: number
    name: string
    systemName: string
    fileType: ProviderOrganizationFileType
}

export enum ProviderOrganizationFileType{
    DEFAULT = 0,
    COPY = 1,
    FILING_PROOF = 2
}