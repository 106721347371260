import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, of, switchMap } from 'rxjs';
import { APIResponseModel } from '../models/api-response-model';
import { FormSubmissionStatus, StatusId } from './form-submissions.service';
import { ICardListInfoData } from '../modules/shared/components/cards/card-list-item/card-list-item.interface';
import { MarketingMaterialStatus } from './marketing-material.service';

export type Kpi =
  | 'mbrreview'
  | 'submission'
  | 'consolidation'
  | 'marketingmaterial';

export interface SubmissionData {
  approved: number;
  rejected: number;
  active: number;
  total: number;
  actions: {
    dueDate: string;
    formNo: string;
    assignee: string;
    status: FormSubmissionStatus[];
    id: number;
    product: string;
  }[];
}
export interface MarketingMaterialData {
  approved: number;
  rejected: number;
  active: number;
  total: number;
  action: {
    dueDate: string;
    marketingMaterialNo: string;
    assignee: string;
    status: FormSubmissionStatus[];
    id: number;
    product: string;
  }[];
}

type MbrReviewData = SubmissionData;
type ConsolidationData = SubmissionData;

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends BaseService {
  dataFormSubmission$: Observable<ICardListInfoData[]>;

  private totalFormSubmissionSubject$: BehaviorSubject<number> =
    new BehaviorSubject(0);
  totalFormSubmission$: Observable<number> =
    this.totalFormSubmissionSubject$.asObservable();

  dataMBR$: Observable<ICardListInfoData[]>;

  private totalMBRSubject$: BehaviorSubject<number> = new BehaviorSubject(0);
  totalMBR$: Observable<number> = this.totalMBRSubject$.asObservable();

  dataFormConsolidation$: Observable<ICardListInfoData[]>;

  private totalFormConsolidationSubject$: BehaviorSubject<number> = new BehaviorSubject(0);
  totalFormConsolidation$: Observable<number> = this.totalFormConsolidationSubject$.asObservable();

  dataMarketingMaterial$: Observable<ICardListInfoData[]>;

  private totalMarketingMaterialSubject$: BehaviorSubject<number> =
    new BehaviorSubject(0);
  totalMarketingMaterial$: Observable<number> =
    this.totalMarketingMaterialSubject$.asObservable();

  constructor(http: HttpClient) {
    super(http, `dashboard`);

    this.dataFormSubmission$ = this.initFormSubmission();
    this.dataMBR$ = this.initMBR();
    this.dataFormConsolidation$ = this.initFormConsolidation();
    this.dataMarketingMaterial$ = this.initMarketingMaterial();

  }

  getKPIs<Type extends Kpi>(
    type: Type
  ): Observable<
    APIResponseModel<
      Type extends 'submission'
        ? SubmissionData
        : Type extends 'mbrreview'
        ? MbrReviewData
        : Type extends 'marketingmaterial'
        ? MarketingMaterialData
        : ConsolidationData
    >
  > {
    return this.http.get<APIResponseModel>(`${this.baseUrl}/${type}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return this.httpErrorHandler(err, []);
      })
    );
  }

  private initFormSubmission() {
    return this.getKPIs('submission').pipe(
      switchMap((subData) => {
        if (!subData.isError) {
          this.totalFormSubmissionSubject$.next(subData.data.total);
          const infoFormSubmission: ICardListInfoData[] =
            subData.data.actions.map((action: any) => {
              const status = action.status.map(this.buildStatusData);
              return this.buildInfoData(action, status, 'submission');
            });

          return of(infoFormSubmission);
        } else {
          return of([]);
        }
      })
    );
  }

  private initMBR() {
    return this.getKPIs('mbrreview').pipe(
      switchMap((subData) => {
        if (!subData.isError) {
          this.totalMBRSubject$.next(subData.data.total);
          const infoMBR: ICardListInfoData[] = subData.data.actions.map(
            (action: any) => {
              const status = this.buildStatusData(
                action.status,
                action.assignee
              );
              return this.buildInfoData(action, [status], 'mbrreview');
            }
          );

          return of(infoMBR);
        } else {
          return of([]);
        }
      })
    );
  }

  private initFormConsolidation() {
    return this.getKPIs('consolidation').pipe(
      switchMap((subData) => {
        if (!subData.isError) {
          this.totalFormConsolidationSubject$.next(subData.data.total);
          const infoFormConsolidation: ICardListInfoData[] = subData.data.actions.map(
            (action: any) => {
              const status = this.buildStatusData(
                action.status,
                action.assignee
              );
              return this.buildInfoData(action, [status], 'consolidation');
            }
          );
          return of(infoFormConsolidation);
        } else {
          return of([]);
        }
      })
    );
  }


  private initMarketingMaterial() {
    return this.getKPIs('marketingmaterial').pipe(
      switchMap((subData) => {
        if (!subData.isError) {
          this.totalMarketingMaterialSubject$.next(subData.data.total);
          const infoMarketingMaterial: ICardListInfoData[] = subData.data.action.map(
            (action: any) => {
              const status = action.status.map(this.buildStatusData);
              return this.buildInfoData(action, status, 'marketingmaterial');
            }
          );


          return of(infoMarketingMaterial);
        } else {
          return of([]);
        }
      })
    );
  }


  private buildStatusData(
    status: FormSubmissionStatus | MarketingMaterialStatus | string,
    assignee?: string
  ): any {
    let value: string = '';

    if (typeof status === 'string') {
      switch (status) {
        case 'Received':
        case 'Sent Back':
          value = 'secondary';
          break;
        case 'Under Review':
        case 'Waiting':
          value = 'info';
          break;
        case 'Action Required':
          value = 'warn';
          break;
        case 'Approved':
          value = 'success';
          break;
        case 'Rejected':
          value = 'error';
          break;
        default:
          value = '';
          break;
      }
    } else {
      switch (status.statusId) {
        case StatusId.Received:
        case StatusId.SentBack:
          value = 'secondary';
          break;
        case StatusId.UnderReview:
          value = 'info';
          break;
        case StatusId.Approved:
          value = 'success';
          break;
        case StatusId.Rejected:
          value = 'error';
          break;
        default:
          value = '';
          break;
      }
    }

    const label = typeof status == 'string' ? assignee : status.name;
    return {
      label,
      value,
    };
  }

  private buildInfoData(
    action: any,
    status: any,
    type: Kpi
  ): ICardListInfoData {
    let label_1: string, label_2: string, label_3: string;
    switch (type) {
      case 'submission':
        label_1 = 'Provider';
        label_2 = 'Product';
        label_3 = 'Form No';
        break;

      case 'mbrreview':
        label_1 = 'Lender';
        label_2 = 'Product';
        label_3 = 'System No';
        break;

      case 'consolidation':
        label_1 = 'Provider';
        label_2 = 'Product';
        label_3 = 'System No';
        break;

      case 'marketingmaterial':
        label_1 = 'Provider';
        label_2 = 'Product';
        label_3 = ''
        break;

      default:
        label_1 = 'Provider';
        label_2 = 'Product';
        label_3 = 'Form No';
        break;
    }

    let list = [
      {
        label: label_1,
        value: action.assignee,
      },
      {
        label: label_2,
        value: action.product,
      },
    ]

    if(type !== 'marketingmaterial') {
      list.push(      {
        label: label_3,
        value: action.formNo,
      })
    }

    return {
      id: action.id,
      list,
      status,
    } as ICardListInfoData;
  }

  private isDateInCurrentWeek(dateString: string): boolean {
    const dateObject = new Date(dateString);
    const currentDate = new Date();

    const firstDayOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDate.getDay()
    );
    const lastDayOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDate.getDay())
    );

    return dateObject >= firstDayOfWeek && dateObject <= lastDayOfWeek;
  }
}
