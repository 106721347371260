<a #popupTrigger data-bs-toggle="modal" data-bs-target="#popup-confirmation"></a>
<div class="modal fade" id="popup-confirmation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">

        <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
                <h5 class="modal-title fw-bold fs-6" id="staticBackdropLabel">
                    {{heading}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="m-0 fs-6">{{message}}<br />
                    Are you sure to proceed?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">{{cancelButton}}</button>
                <button type="button" class="btn btn-primary" (click)="onConfirmation()" >{{confirmButton}}</button>
            </div>
        </div>
    </div>
</div>
