import { IDateFilterParams } from "ag-grid-community";

export const AgDateFormatter = (params: any) => {
    let date = new Date(params.value);
    return date.toLocaleDateString();
}

export const  AgDateFilterParams: IDateFilterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      let cellDate = new Date(new Date(cellValue).getFullYear(), new Date(cellValue).getMonth(), new Date(cellValue).getDate()) ;

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
    maxValidYear: 2023,
  };
