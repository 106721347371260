<div class="sub-comment-container" *ngIf="reply.comment">
  <div class="sub-comment-row">
    <div class="user-avatar-subcomment">
      <span>{{
        reply.firstName + " " + reply.lastName
          | initials
          | uppercase
      }}</span>
    </div>
    <span class="sub-comment-name">{{reply.firstName + " " + reply.lastName}}</span>
    <span class="sub-comment-time">{{reply.dateTime | timeAgo}}</span>
  </div>
  <div class="sub-comment-description"
    [innerHTML]="reply.comment| bypassHtmlSanitizer">
  </div>
  <div class="attachment-row">
    <div class="attachments-container">
      <div
        *ngFor="let attach of reply?.documents"
        class="attachment-item"
        (click)="downloadDocument($event, attach)"
      >
        <mat-icon fontSet="material-icons-outlined">insert_drive_file</mat-icon>
        <span class="attachment-name">{{ attach.name }}</span>
      </div>
    </div>
    <div class="attachment-actions" *ngIf="hasDocuments()">
      <button
        mat-stroked-button
        class="primary outer icon-only"
        (click)="downloadAll($event)"
      >
        <mat-icon
          class="icon-btn card-action-icon"
          fontSet="material-icons-outlined"
          >download</mat-icon
        >
      </button>
    </div>
  </div>
</div>
