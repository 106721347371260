import { Component, Injector, Input, OnInit } from '@angular/core';
import { LenderOrganizationsService } from 'src/app/services/lender.organizations.service';
import { ProviderOrganizationsService } from 'src/app/services/provider.organizations.service';

@Component({
  selector: 'app-organization-card',
  templateUrl: './organization-card.component.html',
  styleUrls: ['./organization-card.component.scss']
})
export class OrganizationCardComponent implements OnInit {

  organizationService: LenderOrganizationsService | ProviderOrganizationsService;
  organizationData: any;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
     if (this.type === "Lender") {
      this.organizationService = this.injector.get(LenderOrganizationsService);
     }
     else {
      this.organizationService = this.injector.get(ProviderOrganizationsService);
     }

     this.organizationService.getById(this.id).subscribe(r => {
      if (!r.isError) {
        this.organizationData = r.data;
      }
     })

  }

  @Input() id: any;
  @Input() type: string;


}