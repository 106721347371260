import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  GridReadyEvent,
  ValueGetterParams,
} from 'ag-grid-community';
import { BtnCellRenderer } from 'src/app/components/btn-cell-renderer/btn-cell-renderer.component';
import { GridBadgeColumnComponent } from 'src/app/modules/shared/components/grid-badge-column/grid-badge-column.component';
import { GridDocumentComponent } from 'src/app/modules/shared/components/grid-document/grid-document.component';
import { PopupConfirmationComponent } from 'src/app/modules/shared/components/popup-confirmation/popup-confirmation.component';
import { UploadDocumentsComponent } from 'src/app/modules/shared/components/upload-documents/upload-documents.component';
import { MBRDocumentsService } from 'src/app/services/mbr-documents.service';
@Component({
  selector: 'app-products-mbr',
  templateUrl: './products-mbr.component.html',
  styleUrls: ['./products-mbr.component.scss'],
})
export class ProductsMbrComponent implements OnInit {
  productId: any;
  isLoading: boolean = false;
  submissionService: any;

  public canUpload: boolean = false;
  public makeAsActive = false;
  public product: { name: string } | undefined;

  constructor(
    private service: MBRDocumentsService,
    private route: ActivatedRoute
  ) {}

  onChange($event: any) {
    this.makeAsActive = $event.target.checked;
  }

  ngOnInit(): void {
    this.productId = this.route.snapshot.paramMap.get('id');
  }
  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'MBR Document',
      flex: 3,
      cellRenderer: GridDocumentComponent,
      valueGetter: (params: ValueGetterParams) => {
        return [params.data];
      },
    },
    {
      field: 'version',
      headerName: 'Version',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      floatingFilter: true,
      sortable: true,
    },
    {
      cellRenderer: GridBadgeColumnComponent,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.isCurrent
          ? [{ class: 'bg-success', value: 'active' }]
          : [];
      },
    },
    {
      headerName: 'Make this Default',
      width: 200,
      filter: false,
      sortable: false,
      cellRenderer: BtnCellRenderer,
      cellClass: 'make-default',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.isCurrent
          ? {}
          : {
              onConfirmation: this.confirmationPopup.bind(this),
              version: params.data.version,
            };
      },
    },
  ];

  public rowData$!: any[];

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  @ViewChild(UploadDocumentsComponent)
  documentUploader: UploadDocumentsComponent;
  @ViewChild(PopupConfirmationComponent) popup: PopupConfirmationComponent;

  onGridReady(params: GridReadyEvent) {
    this.getGridData();
  }

  private getGridData() {
    this.service.getByProduct(this.productId).subscribe((r) => {
      this.rowData$ = r.data.documents;
      this.product = r.data.product;
    });
  }

  public fileRemoved(fileCount: number) {
    if (fileCount === 0) {
      this.canUpload = false;
    } else {
      this.canUpload = true;
    }
  }

  public uploadingEvent(isUploading: boolean) {
    this.isLoading = isUploading;
    if (this.documentUploader.files.length > 0) {
      this.canUpload = true;
    }
  }

  public onSubmit() {
    var data = {
      isActiveVersion: this.makeAsActive,
      document: {
        serverFileName: this.documentUploader.files[0].serverFileName,
        name: this.documentUploader.files[0].file.name,
      },
    };

    this.service.uploadMBR(this.productId, data).subscribe((r) => {
      this.getGridData();
      this.documentUploader.clearFiles();
    });
  }

  confirmationPopup(data: any) {
    this.popup.showPopup(data.version);
  }

  onConfirmation(result: any) {
    if (result.confirmed) {
      this.service.makeDefault(this.productId, result.id).subscribe(() => {
        this.getGridData();
      });
    }
  }
}
