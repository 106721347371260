import { Component, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, ColDef, GridReadyEvent, ValueGetterParams } from 'ag-grid-community';
import { GridDocumentComponent } from 'src/app/modules/shared/components/grid-document/grid-document.component';
import { MBRDocumentsService } from 'src/app/services/mbr-documents.service';
@Component({
  selector: 'app-product-mbr-list',
  templateUrl: './mbr-list.component.html',
  styleUrls: ['./mbr-list.component.scss']
})
export class ProductMbrListComponent {

  isLoading: boolean = false;
  submissionService: any;

  constructor(private service: MBRDocumentsService) {

  }
 // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    {
      field: 'organization.name',
      headerName: 'Lender',
      flex:2,
      filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true
    },
    { field: 'product.name', headerName: 'Product', flex:3, filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true },
    {
      field: 'mbrDocument.name',
      headerName: 'MBR Document',
      flex:3,
      cellRenderer: GridDocumentComponent,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.mbrDocument ? [params.data.mbrDocument] : [];
      }
    },
    { field: 'mbrDocument.version', headerName: 'Version', filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true},
  ];

  public rowData$!: any[];

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  onGridReady(params: GridReadyEvent) {
    this.getGridData();
  }

  private getGridData() {
    this.service.get().subscribe(r => {
      this.rowData$ = r.data;
    });
  }
}
