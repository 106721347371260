<div class="pcs-container">
  <div class="pcs-header">
    <h3 class="pcs-title" *ngIf="product">{{ product.name }} MBRs</h3>
    <div class="card-actions">
      <a
        routerLink="/lender/mbr-request/create"
        mat-flat-button
        color="primary"
        type="button"
        class="btn btn-primary"
        ><mat-icon>add</mat-icon> Request MBR</a
      >
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-8 col-md-10"></div>

      <div class="col-md-12 my-3">
        <div class="form-group">
          <label>Upload MBR</label>
          <app-upload-documents
            [isMulti]="false"
            (onFileRemove)="fileRemoved($event)"
            (uploadingEvent)="uploadingEvent($event)"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group checkbox-radio not-animate mb-3">
          <input
            type="checkbox"
            id="checkbox-6"
            (change)="onChange($event)"
            [disabled]="!canUpload"
          />
          <label for="checkbox-6"></label>
          <label for="checkbox-6">Make this default</label>
        </div>
      </div>
      <div class="text-end">
        <button
          (click)="onSubmit()"
          type="button"
          [disabled]="!canUpload"
          class="btn btn-primary"
        >
          Upload MBR Document
        </button>
      </div>
    </div>
  </div>
  <div class="pro-card">
    <app-ag-grid-wrapper
      [data]="rowData$"
      [columns]="columnDefs"
      (ready)="onGridReady($event)"
    />
  </div>
</div>
<popup-confirmation
  (confirmationResult)="onConfirmation($event)"
  heading="Confirmation"
  message="This document will become the default MBR."
  confirmButton="Confirm"
  cancelButton="Cancel"
></popup-confirmation>
