<div class="delete-dialog-header">
    <h5>{{ title }}</h5>
    <button class="delete-dialog-header-close" (click)="closeDialog()"><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content class="mat-typography">
  <p>{{message}}</p>
  <p>Are you sure to proceed?</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-stroked-button
   [mat-dialog-close]="false"
   class="button-cancel">Cancel</button>
  <button
    mat-flat-button
    color="primary"
    type="button"
    [mat-dialog-close]="true"
    >
    {{actionText}}
    </button>
</mat-dialog-actions>
