<div class="pcs-container">
  <div class="pcs-header">
    <h3 class="pcs-title">Products MBRs</h3>
    <div class="card-actions">
      <a
        routerLink="/lender/mbr-request/create"
        mat-flat-button
        color="primary"
        type="button"
      >
        <mat-icon>add</mat-icon> Request MBR</a
      >
    </div>
  </div>
  <div class="pro-card">
    <app-ag-grid-wrapper
      [data]="rowData$"
      [columns]="columnDefs"
      (ready)="onGridReady($event)"
    />
  </div>
</div>
