export const PERMISSION_CLAIM = "proteriscs/permissions";

export enum Permission{
    FormSubmission = "FormSubmission",
    FormConsolidation = "FormConsolidation",
    MBRReview = "MBRReview",
    Providers = "Providers",
    Lenders = "Lenders",
    Admin = "Admin"
}
