import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, ColDef, GridReadyEvent, ValueGetterParams } from 'ag-grid-community';
import { BtnCellRenderer } from 'src/app/components/btn-cell-renderer/btn-cell-renderer.component';
import { GridDocumentComponent } from 'src/app/modules/shared/components/grid-document/grid-document.component';
import { MBRDocumentsService } from 'src/app/services/mbr-documents.service';
@Component({
  selector: 'app-mbr-list',
  templateUrl: './mbr-list.component.html',
  styleUrls: ['./mbr-list.component.scss']
})
export class MBRListComponent {
  isLoading: boolean = false;
  submissionService: any;

  constructor(private service: MBRDocumentsService, private router: Router) {

  }
 // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    { field: 'product.name', headerName: 'Product', flex: 3, filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true },
    {
      field: 'mbrDocument.name',
      headerName: 'MBR Document',
      flex:3,
      cellRenderer: GridDocumentComponent,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.mbrDocument ? [params.data.mbrDocument] : [];
      }
    },
    { field: 'mbrDocument.version', headerName: 'Version', flex:3, filter: 'agTextColumnFilter', suppressMenu: true, floatingFilter: true, sortable: true},
    {
      field: 'action',
      width:150,
      filter: false,
      sortable:false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onView: this.onView.bind(this),
      },
    },
  ];

  public rowData$!: any[];

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  onGridReady(params: GridReadyEvent) {
    this.getGridData();
  }

  private getGridData() {
    this.service.get().subscribe(r => {
      this.rowData$ = r.data;
    });
  }

  onView(data: any) {
    this.router.navigate([`/lender/product-mbr/${data.product.id}`]);
  }
}
