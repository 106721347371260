import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  title = '';
  message = '';
  constructor(private toastService: ToastService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((res: any) => {
        if (res?.status === 201) {
          this.title = 'Created';
          this.message = res.body.message;
          this.toastService.success(this.title, this.message);
        } else if (res?.status === 200 && res?.body?.message) {
          this.title = 'Success';
          this.message = res.body.message;
          this.toastService.success(this.title, this.message);
        }
        return res;
      }),
      timeout(30000),
      catchError((error: HttpErrorResponse) => {
        console.log('🚀 ~ ErrorInterceptor ~ catchError ~ error:', error);
        if (error instanceof TimeoutError) {
          this.title = 'Connection Timeout';
          this.message = `It looks like we're having trouble connecting. Please try again later.`;
        } else if (error.error instanceof ErrorEvent) {
          this.title = 'Client Error';
          this.message = `Error: ${error.error.message}`;
        } else if (error.status === 0) {
          this.title = 'Server Unreachable';
          this.message = `We can't reach the server at the moment. Please check your connection or try again later.`;
        } else {
          if (
            error.error instanceof Blob &&
            error.error.type === 'application/problem+json'
          ) {
            return new Observable<HttpEvent<any>>((observer) => {
              const reader = new FileReader();
              reader.onload = (e: any) => {
                try {
                  const errorMsg = JSON.parse(e.target.result);
                  this.title = errorMsg?.title || 'Error';
                  this.message =
                    errorMsg?.detail || `Error Code: ${error.status}`;

                  this.toastService.error(this.title, this.message);
                } catch (e) {
                  this.toastService.error(
                    'Error',
                    `Error Code: ${error.status}, Message: ${error.message}`
                  );
                }
                observer.error(error);
              };
              reader.onerror = () => {
                this.toastService.error(
                  'Error',
                  `Error Code: ${error.status}, Message: ${error.message}`
                );
                observer.error(error);
              };
              reader.readAsText(error.error);
            });
          } else {
            this.title = 'Server Error';
            this.message = `Error Code: ${error.status}, Message: ${error.message}`;
            this.toastService.error(this.title, this.message);
          }
        }

        this.toastService.error(this.title, this.message);

        return throwError(() => error);
      })
    );
  }
}
