<a
  class="btn btn-toggle nav-item"
  [ngClass]="{'child-item': isChild, 'root-active': parentActive}"
  [routerLink]="route"
  [routerLinkActive]="isChild ? 'child-active' : 'root-active'"
  [attr.data-bs-toggle]="collapseId ? 'collapse' : ''"
  [attr.data-bs-target]="collapseId"
>
  <ng-content></ng-content>
  <span>{{ text }}</span>
</a>
