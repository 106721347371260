<div *ngIf="organizationId" class="pcs-container">
    <div class="pcs-header">
      <h3 class="pcs-title">Provider List</h3>
        <div class="card-actions">
  
        </div>
      </div>
      <br />
      <div  class="card-body">
        <!-- AG Grid Angular Component -->
  
        <app-organization-card type="Provider" [id]="organizationId" />
  
        <br />
        <div>
          <mat-tab-group class="provider-tabs" mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab label="Users"> 
              <ng-template matTabContent>
                <div class="pcs-container">
                  <div class="pcs-header">
                    <h3 class="pcs-title">Users</h3>
                  </div>
                  <div class="pro-card">
                    <app-ag-grid-wrapper
                      [data]="rowData$"
                      [columns]="columnDefs"
                      (ready)="onGridReady($event)"
                    />
                  </div>
                </div>
              </ng-template>
            </mat-tab>
            <mat-tab *ngFor="let documentType of documentTypes" [label]="documentType.name">
              <ng-template matTabContent>
                <app-provider-documents
                [organizationId]="organizationId"
                [documentType]="documentType"
                [states]="states"
                [products]="products"
                >
                </app-provider-documents>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
      </div>
    </div>
  </div>