<div class="pro-card">
  <h3 class="pro-card-title">{{ title }}</h3>
  <div class="pro-card-content">
    <ng-container *ngIf="!loading;else loadingTemplate">
      <h2 class="pro-card-value">{{ value }}</h2>
      <!-- Remove temporary -->
      <!-- <div class="due-this-week-tag" [ngClass]="{zero: value == 0}">
        <span class="material-icons clock">schedule</span>
        <span class="text">Due this week</span>
      </div> -->
    </ng-container>
    <ng-template #loadingTemplate>
      <app-loader />
    </ng-template>
  </div>
</div>
