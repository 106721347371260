import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusBadgeComponent } from '../../status-badge/status-badge.component';
import { ICardListInfo, ICardListStatus } from '../card-item.interface';
import { Router, RouterModule } from '@angular/router';
import { InitialsPipe } from '../../../pipes/initials.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { IHeaderAction } from '../../header-actions/header-actions.interface';

@Component({
  selector: 'app-card-grid-item',
  standalone: true,
  imports: [CommonModule, RouterModule, StatusBadgeComponent, InitialsPipe, MatIconModule, MatButtonModule],
  templateUrl: './card-grid-item.component.html',
  styleUrls: ['./card-grid-item.component.scss'],
})
export class CardGridItemComponent {
  @Input() id: number;
  @Input() title: string;
  @Input() infoContent: ICardListInfo[];
  @Input() statusContent: ICardListStatus[];
  @Input() actionList: IHeaderAction[];
  @Output() actionClicked: EventEmitter<{ actionId: string, id: number}> = new EventEmitter();

  constructor(private router: Router) {}

  getExtraCount(): string {
    return `+${this.statusContent.length - 3}`;
  }

  isArray(value: any) {
    return Array.isArray(value);
  }
  
  getInfoValue(info: ICardListInfo): { name: string }[] {
    return info.value;
  }

  onActionClicked(actionId: string) {
    this.actionClicked.emit({ actionId, id:this.id });
  }
}
