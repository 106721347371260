import { Component } from '@angular/core';
import { InactivityService } from './services/inactivity.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
  title = 'proto-routing';

  constructor(private inactivityService: InactivityService) {
    
  }
}
