import { Component, Input } from '@angular/core';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
  selector: 'app-attachments-group',
  templateUrl: './attachments-group.component.html',
  styleUrls: ['./attachments-group.component.scss']
})
export class AttachmentsGroupComponent {

  constructor(private authService: AuthenticationService, private documentService: DocumentsService) {
  }



  @Input() attachments: any;
  @Input() header: string = 'Attachments';
  @Input() headerClass: string = '';

  downloadFile(file: any) {
    this.documentService.downloadDocument(file);
  }

  getDocType(file: any): string {
    return file.name.split('.')[file.name.split('.').length - 1];
  }
}
