<div
  class="root"
  [ngStyle]="{
    backgroundColor: backgroundColor,
    color: color,
    outline:
      '1.25px solid ' + borderColor,
  }"
  matTooltipClass="custom-tooltip"
  [matTooltip]="getToolTipText()"
>
  <span class="iconContainer">
    <span class="material-icons-outlined icon"
      >{{icon}}</span
    >
  </span>
  <span *ngIf="showText">{{ status.statusText }}</span>
</div>
