import { Injectable, NgZone } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AuthService } from '@auth0/auth0-angular';
import { map, Observable, of, Subscription, takeWhile, timer } from 'rxjs';
import { GeneralDialogComponent } from '../modules/shared/components/general-dialog/general-dialog.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private readonly INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutos
  private readonly WARNING_TIMEOUT = 25 * 60 * 1000; // 25 minutos
  private isAuthenticated: Subscription;
  private inactivityTimer: Subscription;
  private warningTimer: Subscription;
  private countdown$: Observable<number>;
  private warningDialogRef: MatDialogRef<GeneralDialogComponent | any>;
  dialogOpen: boolean = false;
  timeToContinueActivity: number;

  resetInactivityTimerOnCLick: () => void = this.resetInactivityTimer.bind(this);

  constructor(
    private auth0Service: AuthService,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private router: Router
  ) {
    const diferenceBetweenTimeouts = this.INACTIVITY_TIMEOUT - this.WARNING_TIMEOUT;
    this.timeToContinueActivity = diferenceBetweenTimeouts > 0 ? diferenceBetweenTimeouts : 1;

    this.isAuthenticated = this.auth0Service.isAuthenticated$.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.trackUserActivity();
        this.startInactivityTimer();
      }
      else{
        this.untrackUserActivity();
        this.unsuscribeTimers();
      }
    });

    this.countdown$ = timer(0, 1000).pipe(
      map(t => Math.floor((this.timeToContinueActivity - t * 1000) / 1000)),
      takeWhile(t => t >= 0)
    );
  }

  public startInactivityTimer() {
    this.resetTimer();
  }

  public resetInactivityTimer(){
    this.resetSessionInAuth0();
    this.resetTimer();
  }

  public resetTimer() {
    this.unsuscribeTimers();   
    this.warningTimer = timer(this.WARNING_TIMEOUT).subscribe(() => this.showWarning());
    this.inactivityTimer = timer(this.INACTIVITY_TIMEOUT).subscribe(() => this.logout());
  }

  public trackUserActivity(){
    document.addEventListener('click', this.resetInactivityTimerOnCLick);
  }

  public untrackUserActivity(){
    document.removeEventListener('click', this.resetInactivityTimerOnCLick);
  }

  private unsuscribeTimers(){
    if (this.inactivityTimer) {
      this.inactivityTimer.unsubscribe();
    }
    if (this.warningTimer) {
      this.warningTimer.unsubscribe();
    }
  }

  private resetSessionInAuth0(){
    this.auth0Service.getAccessTokenSilently({cacheMode: 'off'}).subscribe();
  }

  private showWarning() {
    this.untrackUserActivity();
    this.ngZone.run(() => {
      const countdownMessage$ = this.countdown$.pipe(
        map(time => `Your session will expire in: ${time} seconds`)
      );
      this.warningDialogRef = this.dialog.open(GeneralDialogComponent, {
        width: '500px',
        disableClose: true,
        data: {
          title: 'Warning - Session Inactivity',
          message$: countdownMessage$,
          buttonText: 'Continue'
        }
      });

      this.dialogOpen = true;

      this.warningDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.trackUserActivity();
          this.resetInactivityTimer();
        }
      });
    });
    this.warningTimer.unsubscribe();
  }

  private logout() {
    this.auth0Service.logout();
  }
}
