import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule]
})
export class NavItemComponent{
  @Input() text: string;
  @Input() route?: string | null;
  @Input() isChild: boolean = false;
  @Input() collapseId?: string;
  @Input() parentActive?: boolean;
}
