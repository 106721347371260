<ng-multiselect-dropdown
    [placeholder]="label"
    [settings]="settings"
    [data]="dataSource"
    [disabled]="disabled"
    [ngModel]="selectedItems"
    (onSelect)="onSelect($event)"
    (onDeSelect)="onDeSelect($event)"
    (onSelectAll)="onSelectAll($event)"
    (onDeSelectAll)="onDeSelectAll($event)"
    (onDropDownClose)="onClose()"
>
</ng-multiselect-dropdown>
<div *ngIf="(getFormControl().dirty ||getFormControl().touched)">
    <div class="error" *ngIf="getFormControl().errors?.['required']">
        {{'Select ' + label }}
    </div>
</div>