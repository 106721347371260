import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UploadDocumentsComponent } from '../upload-documents/upload-documents.component';
import { FormSubmissionsService } from 'src/app/services/form-submissions.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormConsolidationsService } from 'src/app/services/form-consolidations.service';
import { MBRRequestsService } from 'src/app/services/mbr-requests.service';
import { MarketingMaterialService } from 'src/app/services/marketing-material.service';

@Component({
  selector: 'app-reply-box',
  templateUrl: './reply-box.component.html',
  styleUrls: ['./reply-box.component.scss']
})
export class ReplyBoxComponent implements OnInit {

  isLoading: boolean = false;
  private service: FormSubmissionsService | FormConsolidationsService| MBRRequestsService | MarketingMaterialService;

  form: FormGroup = new FormGroup({
    comments: new FormControl('', [Validators.required])
  });

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    if (this.type === 'submission') {
      this.service = this.injector.get(FormSubmissionsService);
    }
    else if (this.type === 'consolidation') {
      this.service = this.injector.get(FormConsolidationsService);
    }
    else if (this.type === 'marketingmaterial') {
      this.service = this.injector.get(MarketingMaterialService);
    } else if (this.type === 'mbr-request') {
      this.service = this.injector.get(MBRRequestsService);
    }
  }

  @Input() entityId: string;
  @Input() hide: boolean;
  @Input() type: string;
  @Output() actionResult = new EventEmitter<any>();

  uploadingEventHandler(status: boolean): void {
    this.isLoading = status;
  }


  onSubmit() {

    this.isLoading = true;

    let data = this.form.value;
    data['documents'] = this.documentUploader.uploadedFiles();

    this.service.updateStatus(this.entityId, 'sendback', data)
      .subscribe(r => {
        this.isLoading = false;
        this.form.reset();
        this.actionResult.emit({ result: true});
      });

  }

  onCancel() {
    this.form.reset();
    this.actionResult.emit({ result: false });
  }

  @ViewChild(UploadDocumentsComponent) documentUploader: UploadDocumentsComponent;
}
