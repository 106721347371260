import { Injectable } from '@angular/core';
import { ADMIN_NAV_ROUTES, INavRoute, LENDERS_NAV_ROUTES, PROVIDERS_NAV_ROUTES, ROLE_ROUTE, SYSTEM_USER_NAV_ROUTES } from './nav-routes';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, map, of, switchMap, tap } from 'rxjs';
import { ROLE_CLAIM } from 'src/app/models/user/role.model';
import { PERMISSION_CLAIM } from 'src/app/models/user/permissions.model';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class NavRoutesService {
  public navRoutes$: Observable<INavRoute[]>;

  constructor(private auth: AuthenticationService) {
    this.navRoutes$ = auth.user$.pipe(
      switchMap(user => {
        const userRole = user![ROLE_CLAIM];
        const permissions = user![PERMISSION_CLAIM];
        return this.getRouteByRole(userRole).pipe(
          map(navRoutes => {
            if(userRole == 'Admin') return navRoutes; // Admin don't need to check permission till now
            return this.removeUnauthorizedRoutes(navRoutes, permissions)
          }),
        );
      })
    )
  }

  getRouteByRole(role: ROLE_ROUTE): Observable<INavRoute[]> {
    switch (role) {
      case 'Admin':
        return of(ADMIN_NAV_ROUTES);
      case 'SystemUser':
          return of(SYSTEM_USER_NAV_ROUTES);
      case 'Provider':
          return of(PROVIDERS_NAV_ROUTES);
      case 'Lender':
          return of(LENDERS_NAV_ROUTES);

      default:
        return of(ADMIN_NAV_ROUTES);
    }
  }

  removeUnauthorizedRoutes(navRoutes: INavRoute[], permissions: string[]): INavRoute[] {
    return navRoutes.filter(route => {
      return (
        (!route.permission || route.permission.some(perm => permissions.includes(perm))) &&
        (!route.children || route.children.every(child => !child.permission || child.permission.some(perm => permissions.includes(perm))))
      );
    });
  }

}
