import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageRendererService {
  readonly ICONS_ROUTE: string = 'assets/images/icons'

  getIconsPath(imageName: string): string {
    return `${this.ICONS_ROUTE}/${imageName}`;
  }
}
