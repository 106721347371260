<div class="col-lg-8 col-md-10">
    <div class="card attachment-card">
        <div class="card-header"><span [ngClass]="headerClass">{{header}}</span></div>
        <div class="card-body">
            <ul>
                <li *ngFor="let doc of attachments">
                    <div class="file-type"><i class="far fa-file-word"></i></div>
                    <div class="file-name">{{doc.name}}</div>
                    <div class="download link" (click)="downloadFile(doc)"><button class="btn btn-icon-fill"><i
                                class="fal fa-arrow-to-bottom"></i></button></div>
                </li>
            </ul>
        </div>
    </div>
</div>