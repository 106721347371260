import {AbstractControl, AsyncValidatorFn} from '@angular/forms';
import { BaseService } from '../services/base-service';
import { map, of } from 'rxjs';

export function entityExistsValidator(service: BaseService, currentId?: string): AsyncValidatorFn  {
    return (control: AbstractControl) => {
        if (!control.value) {
            return of(null);
          }
      
        if (currentId && control.value === currentId) {
            return of(null);
        }

        return service.entityExists(control.value)
            .pipe(
                map(res => res.data ? { entityExists:true } : null)
            );
    }
}
