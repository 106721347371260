import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UploadDocumentsComponent } from 'src/app/modules/shared/components/upload-documents/upload-documents.component';
import { CommonService } from 'src/app/services/common.service';
import { MBRRequestsService } from 'src/app/services/mbr-requests.service';

@Component({
  selector: 'app-mbr-request',
  templateUrl: './mbr-request.component.html',
  styleUrls: ['./mbr-request.component.scss']
})
export class MbrRequestComponent {

  isLoading: boolean = false;

  @ViewChild(UploadDocumentsComponent) documentUploader: UploadDocumentsComponent;

  constructor(private mbrRequestService: MBRRequestsService, private router: Router) {
  }

  form: FormGroup = new FormGroup({
    productId: new FormControl('', { validators: [Validators.required]}),
    notes: new FormControl(''),
    dueDate: new FormControl('', { validators: [(control) => CommonService.dueDateValidator(control)]} )
  }); 

  onSubmit() {

    this.isLoading = true;

    let data = this.form.value;
    data['documents'] = this.documentUploader.uploadedFiles();

    this.mbrRequestService.add(data)
      .subscribe(r => {
        this.router.navigate(['lender/mbr-request-list']);
      });

  }

  uploadingEventHandler(status: boolean): void {
    this.isLoading = status;
  }

}
