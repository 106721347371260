<div class="pcs-container">
  <div class="pcs-header">
    <h3 class="pcs-title">MBR Request</h3>
    <div class="card-actions">
      <!-- <a [routerLink]="null" class="btn btn-primary"><i class="fal fa-plus"></i> Add</a> -->
    </div>
  </div>
  <br>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-8 col-md-10">
        <form class="animate-labels-form" [formGroup]="form">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <app-select-dropdown
                  [disabled]="isLoading"
                  [displayField]="'name'"
                  [valueField]="'id'"
                  [label]="'Product'"
                  [serviceUrl]="'products'"
                  [control]="form.controls['productId']"
                ></app-select-dropdown>
                <div
                  *ngIf="
                    form.controls['productId'].dirty ||
                    form.controls['productId'].touched
                  "
                >
                  <div
                    class="error"
                    *ngIf="form.controls['productId'].errors?.['required']"
                  >
                    Please select Product
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  onclick="(this.type='date')"
                  id="date-string"
                  type="date"
                  class="form-control"
                  placeholder=" "
                  formControlName="dueDate"
                />
                <label class="form-label">Review Deadline</label>
                <div
                  *ngIf="
                    form.controls['dueDate'].dirty ||
                    form.controls['dueDate'].touched
                  "
                >
                  <div
                    class="error"
                    *ngIf="!form.controls['dueDate']?.['valid']"
                  >
                    Please select 2 weeks in the future
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <textarea
                  class="form-control floating-textarea"
                  placeholder=" "
                  [disabled]="isLoading"
                  formControlName="notes"
                ></textarea>
                <label>Notes</label>
              </div>
            </div>
            <div class="col-md-12 my-3">
              <app-upload-documents
                (uploadingEvent)="uploadingEventHandler($event)"
              ></app-upload-documents>
            </div>
          </div>

          <div class="text-end mt-3">
            <button
              type="submit"
              (click)="onSubmit()"
              class="btn btn-primary"
              [disabled]="isLoading || form.invalid"
            >
              <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i>
              {{ isLoading ? "Loading..." : "Submit Request" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
