<mat-checkbox
  color="primary"
  disableRipple
  [indeterminate]="isIndeterminate()"
  [checked]="isChecked()"
  (click)="$event.stopPropagation()"
  (change)="toggleSelection($event)"
  class="select-all-checkbox"
>
  {{ isChecked() ? 'Unselect All' : 'Select All' }}
</mat-checkbox>
